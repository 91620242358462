import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Typography from "@mui/material/Typography";
import AppTextField from "../components/AppTextField";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { FormHelperText, InputAdornment, Stack } from "@mui/material";
import { countries } from "../utils/countries";
import { useContext, useRef, useState } from "react";
import { SCREEN_NAME } from ".";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toastTypes } from "../components/AppToast";
import { useAuthentication } from "../hooks/useAuthentication";
import IsLoadingAppButton from "../components/IsLoadingAppButton";
import { Store } from "../store";
import { saveQuery, showToast } from "../actions";

export const OTPScreenMode = {
  SignUp: "SignUp",
  ForgotPassword: "forgotPassword",
};

const FormBoxStyle = {
  display: {
    md: "flex",
  },
  columnGap: 1,
  flexDirection: {
    xs: "column",
    md: "row",
  },
  // px: 3,
};

export default function SignUpPage() {
  const [globalFormError, setGlobalFormError] = useState<string | undefined>(
    undefined
  );
  const { dispatch } = useContext(Store);
  const toastRef = useRef<any>(null);
  const [country, setCountry] = useState(countries[0]);
  const { setPage } = useAppNavigation();
  const handleGoToLoginPage = () => setPage(SCREEN_NAME.login);
  const {
    authProvider: { signUp },
  } = useAuthentication();
  const initialValues = {
    username: "",
    yourName: "",
    phoneNumber: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    country: country?.name as string,
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().min(1).required("username is required"),
    yourName: Yup.string().trim().min(1).required("Full name is required"),
    lastName: Yup.string().trim().min(1),
    country: Yup.string().trim().min(1).required("Country is required"),
    phoneNumber: Yup.string()
      .trim()
      .min(10)
      .required("Phone number is required"),
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    confirmEmail: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required")
      .oneOf([Yup.ref("email")], "Your emails do not match."),
    password: Yup.string()
      .min(6, "password is too short")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .min(6, "password is too short")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
  });
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ username, yourName, email, password, phoneNumber }) => {
      const [firstName, lastName] = yourName.split(" ");
      if (!lastName) {
        setGlobalFormError("You are required to have a last name");
        return;
      }
      try {
        const response = await signUp({
          username,
          firstName: firstName,
          lastName: lastName,
          password,
          email,
          phoneNumber: `${country.dial_code}${phoneNumber}`,
        });
        if (!response.success) {
          dispatch(
            showToast({
              show: true,
              message: "There was a problem registering. Try again later",
              ref: toastRef,
              type: toastTypes.error,
            })
          );
        }
        dispatch(
          saveQuery({ username: username, otpModeScreen: OTPScreenMode.SignUp })
        );
        setPage(SCREEN_NAME.otp);
      } catch (ex: any) {
        console.log(ex.message);
        dispatch(
          showToast({
            show: true,
            message: "Unable to signup",
            ref: toastRef,
            type: toastTypes.error,
          })
        );
      }
    },
  });
  const handleSelectCountry = (event: React.FormEvent<HTMLFormElement>) => {
    handleChange(event);
    const con = countries.filter(
      (coun: any) => coun.name === event.currentTarget.value
    );
    setCountry(con[0]);
  };

  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Avatar sx={{ mx: 1, bgcolor: "primary.main" }} alt="Registration Icon">
        <HowToRegIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mt: 0,
          width: {
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <Grid container spacing={{ md: 2 }}>
          {globalFormError && (
            <FormHelperText
              style={{
                color: "red",
                fontSize: 8,
                marginTop: 0,
                padding: 5,
              }}
            >
              {globalFormError}
            </FormHelperText>
          )}
          <Grid item xs={12} md={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              id="yourName"
              label="Full Name"
              name="yourName"
              autoComplete="yourName"
              placeholder="FirstName LastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.yourName || ""}
              error={Boolean(touched.yourName && errors.yourName)}
              showErrorMessage={Boolean(touched.yourName && errors.yourName)}
              errorMessage={errors.yourName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              onChange={handleChange}
              value={values.username || ""}
              error={Boolean(touched.username && errors.username)}
              showErrorMessage={Boolean(touched.username && errors.username)}
              errorMessage={errors.username}
            />
          </Grid>
        </Grid>
        <Box>
          <AppTextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email || ""}
            error={Boolean(touched.email && errors.email)}
            showErrorMessage={Boolean(touched.email && errors.email)}
            errorMessage={errors.email}
          />
        </Box>
        <Box>
          <AppTextField
            margin="normal"
            required
            fullWidth
            id="confirmEmail"
            label="Confirm Email"
            name="confirmEmail"
            autoComplete="confirmEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.confirmEmail || ""}
            error={Boolean(touched.confirmEmail && errors.confirmEmail)}
            showErrorMessage={Boolean(
              touched.confirmEmail && errors.confirmEmail
            )}
            errorMessage={errors.confirmEmail}
          />
        </Box>
        <Grid container spacing={{ md: 2 }}>
          <Grid item xs={12} md={6}>
            <AppTextField
              margin="normal"
              label="Select a country"
              required
              fullWidth
              id="country"
              name="country"
              autoComplete="country"
              select
              SelectProps={{
                native: true,
              }}
              onBlur={handleBlur}
              onChange={handleSelectCountry}
              value={values.country || ""}
              error={Boolean(touched.country && errors.country)}
              showErrorMessage={Boolean(touched.country && errors.country)}
              errorMessage={errors.country}
            >
              {countries.map((country: any) => (
                <option value={country.name} key={country.name}>
                  {country.name}
                </option>
              ))}
            </AppTextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              autoComplete="phoneNumber"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {country.dial_code}
                  </InputAdornment>
                ),
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phoneNumber || ""}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              showErrorMessage={Boolean(
                touched.phoneNumber && errors.phoneNumber
              )}
              errorMessage={errors.phoneNumber}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            ...FormBoxStyle,
          }}
        >
          <AppTextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            error={Boolean(touched.password && errors.password)}
            showErrorMessage={Boolean(touched.password && errors.password)}
            errorMessage={errors.password}
          />
          <AppTextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.confirmPassword || ""}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            showErrorMessage={Boolean(
              touched.confirmPassword && errors.confirmPassword
            )}
            errorMessage={errors.confirmPassword}
          />
        </Box>
        <Box
          sx={{
            ...FormBoxStyle,
            flexDirection: "column",
          }}
        >
          <IsLoadingAppButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            loading={isSubmitting}
          >
            Sign Up
          </IsLoadingAppButton>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={handleGoToLoginPage}>
                {"Login"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
}
