import { Typography } from "@mui/material";

const SmallText = (props: any) => {
  return (
    <Typography
      {...props}
      sx={{
        fontSize: {
          xs: 10,
          sm: 12,
          md: 15,
        },
      }}
    >
      {props.children}
    </Typography>
  );
};

export default SmallText;
