import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockResetIcon from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import AppTextField from "../components/AppTextField";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { useAuthentication } from "../hooks/useAuthentication";
import { useContext, useEffect, useRef, useState } from "react";
import AppToast, { toastType, toastTypes } from "../components/AppToast";
import { Store } from "../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import IsLoadingAppButton from "../components/IsLoadingAppButton";
import { SCREEN_NAME } from ".";
import { Stack } from "@mui/material";
import { clearQuery } from "../actions";

const FormBoxStyle = {
  display: {
    md: "flex",
  },
  columnGap: 1,
  flexDirection: {
    xs: "column",
    md: "column",
  },
  px: 3,
};

export default function PasswordResetPage() {
  const { state, dispatch } = useContext(Store);
  const { user, param } = state;
  const toastRef = useRef<any>(null);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState<toastType>(toastTypes.success);
  const { setPage } = useAppNavigation();
  const {
    authProvider: { forgotPasswordSetNewPassword },
  } = useAuthentication();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "password is too short")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .min(6, "password is too short")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ password }) => {
      try {
        const { username, otp } = param;
        await forgotPasswordSetNewPassword(username, otp, password);
        dispatch(clearQuery());
        setPage(SCREEN_NAME.passwordResetSuccess);
      } catch (ex) {
        setToastMessage("unable to set new password");
        setToastType(toastTypes.error);
        toastRef.current.openSheet();
      }
    },
  });

  useEffect(() => {
    if (user) {
      setPage(SCREEN_NAME.dashboard);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Avatar sx={{ m: 0, bgcolor: "primary.main" }}>
        <LockResetIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Set new password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Box
          sx={{
            ...FormBoxStyle,
          }}
        >
          <AppTextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            error={Boolean(touched.password && errors.password)}
            showErrorMessage={Boolean(touched.password && errors.password)}
            errorMessage={errors.password}
          />
          <AppTextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            autoComplete="confirm-password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.confirmPassword || ""}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            showErrorMessage={Boolean(
              touched.confirmPassword && errors.confirmPassword
            )}
            errorMessage={errors.confirmPassword}
          />
          <IsLoadingAppButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            loading={isSubmitting}
          >
            Set Password
          </IsLoadingAppButton>
        </Box>
      </Box>
      <AppToast ref={toastRef} type={toastType} message={toastMessage} />
    </Stack>
  );
}
