import { Stack, Typography } from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AppButton from "../components/AppButton";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { SCREEN_NAME } from ".";

const PaymentSuccessPage = () => {
  const { setPage } = useAppNavigation();
  const handleGoToTransactionHistory = () => {
    setPage(SCREEN_NAME.history);
  };
  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography
        fontSize={30}
        textAlign={"center"}
        fontWeight={"bold"}
        color="primary.main"
      >
        Payment in progress
      </Typography>
      <Stack
        sx={{
          width: {
            md: "50%",
            sm: "90%",
            xs: "90%",
          },
          gap: 5,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HourglassBottomIcon
          sx={{
            fontSize: 150,
            animation: "spin 10s linear infinite",
            "@keyframes spin": {
              "0%": {
                transform: "rotate(360deg)",
              },
              "100%": {
                transform: "rotate(0deg)",
              },
            },
          }}
          color="primary"
        />
        <Stack flexDirection={"column"}>
          <Typography textAlign={"center"} color={"primary.main"}>
            Your transaction is in progress, we will inform via text message on
            the status of the transaction.
          </Typography>
          <Typography
            component={"em"}
            // fontStyle={"italic"}
            fontSize={10}
            textAlign={"center"}
            color="gray"
          >
            Note that transaction ideally takes a minute or less
          </Typography>
        </Stack>
        <AppButton sx={{ width: 250 }} onClick={handleGoToTransactionHistory}>
          Go to transaction history
        </AppButton>
      </Stack>
    </Stack>
  );
};

export default PaymentSuccessPage;
