import {
  Avatar,
  Box,
  Fab,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import AppTextField from "../components/AppTextField";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { KeyboardArrowDown } from "@mui/icons-material";
import { countries } from "../data/countries";
import { useContext, useEffect, useState } from "react";
import useAPI from "../hooks/useApi";
import { Store } from "../store";
import { setFromCurrency, setToCurrency } from "../actions";
import AppButton from "../components/AppButton";
import { useAuthentication } from "../hooks/useAuthentication";
import { SCREEN_NAME } from ".";
import { useAppNavigation } from "../hooks/useAppNavigation";

const formStates = {
  usdToGhs: "usdToGhs",
  ghsToUsd: "ghsToUsd",
};

const labels: any = {
  usdToGhs: {
    fromCurrency: "You send",
    toCurrency: "They receive",
  },
  ghsToUsd: {
    toCurrency: "You send",
    fromCurrency: "They receive",
  },
};

const HomePage = () => {
  const { getRates, getInternalRates } = useAPI();
  const { user } = useAuthentication();
  const { setPage } = useAppNavigation();
  const { state, dispatch } = useContext(Store);
  const { currencyState } = state;
  const { fromCurrency, toCurrency } = currencyState;
  const [flipState, setFlipState] = useState(formStates.usdToGhs);
  const [converter, setConverter] = useState({
    amount: 1,
    convertAmount: 0,
  });
  const [rate, setRate] = useState<any>(null);

  const handleSetToCurrency = (e: any) => {
    const val: any = countries.filter(
      (country: any) => country.code === e.currentTarget.value
    );
    dispatch(setToCurrency(val[0]));
  };
  const handleSetFromCurrency = (e: any) => {
    const val: any = countries.filter(
      (country: any) => country.code === e.currentTarget.value
    );
    dispatch(setFromCurrency(val[0]));
  };
  const handleChangeAmount = (e: any) => {
    setConverter({
      ...converter,
      amount: e.currentTarget.value,
      convertAmount: e.currentTarget.value * +rate[toCurrency.code],
    });
  };

  const handleConvertCurrency = async () => {
    try {
      const response = !user ? await getRates() : await getInternalRates();
      setRate(response?.exchangeRates);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToSignUpPage = () => {
    setPage(SCREEN_NAME.signUp);
  };

  const handleGoToLogInPage = () => {
    setPage(SCREEN_NAME.login);
  };

  const handleFlipState = () => {
    // setConverter({
    //   ...converter,
    //   amount: converter.convertAmount,
    //   convertAmount: +converter.amount * +rate[toCurrency.code],
    // });
    setFlipState(
      flipState === formStates.ghsToUsd
        ? formStates.usdToGhs
        : formStates.ghsToUsd
    );
  };

  // to expensive to call the convert currency endpoint every time an amount changes
  useEffect(() => {
    handleConvertCurrency();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Stack sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <AppTextField
            fullWidth
            label="Amount"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {flipState === formStates.usdToGhs
                    ? fromCurrency.code
                    : toCurrency.code}
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              style: { fontSize: 20 },
            }}
            onChange={handleChangeAmount}
            // onKeyUp={() => handleChangeAmount}
            value={converter.amount}
            disabled={!rate}
          />
          <Stack
            flexDirection={
              flipState === formStates.usdToGhs ? "column" : "column-reverse"
            }
          >
            <AppTextField
              label={labels[flipState].fromCurrency}
              select
              fullWidth
              name="paymentMethod"
              SelectProps={{
                native: true,
                IconComponent: KeyboardArrowDown,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Avatar
                      alt="Country Flag"
                      src={fromCurrency.flag}
                      sx={{ width: 30, height: 30 }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSetFromCurrency}
              value={fromCurrency.code}
              disabled
            >
              {countries.map((country: any) => (
                <option key={country.name} value={country.code}>
                  {country.name}
                </option>
              ))}
            </AppTextField>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Fab
                aria-label="convert"
                sx={{
                  display: "flex",
                  justifySelf: "flex-start",
                }}
                size="small"
                color="primary"
                onClick={handleFlipState}
              >
                <SwapVertIcon />
              </Fab>
            </Box>
            <AppTextField
              label={labels[flipState].toCurrency}
              select
              fullWidth
              name="paymentMethod"
              SelectProps={{
                native: true,
                IconComponent: KeyboardArrowDown,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Avatar
                      alt="Country Flag"
                      src={toCurrency.flag}
                      sx={{ width: 30, height: 30 }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSetToCurrency}
              value={toCurrency.code}
              disabled
            >
              {countries.map((country: any) => (
                <option key={country.name} value={country.code}>
                  {country.name}
                </option>
              ))}
            </AppTextField>
          </Stack>
          {rate ? (
            <>
              {flipState === formStates.usdToGhs && (
                <Typography
                  color={"primary"}
                  sx={{
                    fontSize: {
                      md: 23,
                      sm: 18,
                    },
                    textAlign: "left",
                    fontWeight: "bold",
                    px: 0.5,
                    mt: 0.5,
                  }}
                >
                  Today's Rate:
                  {converter.amount}
                  {/* {fromCurrency.code} ≈{" "} */}
                  {/* {(
                +(converter.amount * +rate[toCurrency.code]) /
                +rate[fromCurrency.code]
              ).toFixed(2)} */}
                  {fromCurrency.code} ≈{" "}
                  {(+(converter.amount * +rate)).toFixed(2)}
                  {toCurrency.code}
                </Typography>
              )}

              {flipState === formStates.ghsToUsd && (
                <Typography
                  color={"primary"}
                  sx={{
                    fontSize: {
                      md: 23,
                      sm: 18,
                    },
                    textAlign: "left",
                    fontWeight: "bold",
                    px: 0.5,
                    mt: 0.5,
                  }}
                >
                  Today's Rate:
                  {converter.amount}
                  {/* {fromCurrency.code} ≈{" "} */}
                  {/* {(
                +(converter.amount * +rate[toCurrency.code]) /
                +rate[fromCurrency.code]
              ).toFixed(2)} */}
                  {toCurrency.code} ≈ {(+(converter.amount / +rate)).toFixed(2)}
                  {fromCurrency.code}
                </Typography>
              )}
            </>
          ) : (
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          )}
        </Box>
        {!user && (
          <Stack sx={{ gap: 3 }}>
            <AppButton onClick={handleGoToSignUpPage}>SignUp</AppButton>
            <AppButton variant="outlined" onClick={handleGoToLogInPage}>
              LogIn
            </AppButton>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default HomePage;
