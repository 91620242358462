import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import { Store } from "../store";
import { showToast } from "../actions";

export type toastType = "success" | "error" | "info" | "warning";

type toastTypesType = {
  success: toastType;
  error: toastType;
  info: toastType;
  warning: toastType;
};

export const toastTypes: toastTypesType = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
};

type AppToastProps = {
  type: toastType;
  message: string;
};

function AppToast({ type, message }: AppToastProps, ref: any) {
  const [open, setOpen] = React.useState(false);
  const { dispatch } = React.useContext(Store);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(showToast({ show: false }));
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useImperativeHandle(ref, () => ({
    openSheet: () => handleClickOpen(),
  }));

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default React.forwardRef(AppToast);
