import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import AppStepper from "../components/AppStepper";
import AmountForm from "./transferForm/Amount";
import AppButton from "../components/AppButton";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReceiverForm, { TelcomProviders } from "./transferForm/Receiver";
import SummaryPage from "./transferForm/Summary";
import PaymentForm from "./transferForm/PaymentForm";
// import { SCREEN_NAME } from ".";
import * as Yup from "yup";
import { useFormik, ErrorMessage } from "formik";
import AppBottomSheet from "../components/AppBottomSheet";
import SquarePaymentGateWay from "../components/payment/SquarePaymentGateWay";
import { ReceiveMethod, TransferModel } from "../typing";
import { Store } from "../store";
import IsLoadingAppButton from "../components/IsLoadingAppButton";
import {
  limitMessage,
  limitPaymentMethods,
  paymentMethods,
} from "./LocalMoneyTransfer";
import { countries } from "../utils/countries";
import LoadingModal from "../components/LoadingModal";
import { closeLoader, isLimit, openLoader } from "../utils";
import useAPI from "../hooks/useApi";
import { saveMoneyTransferData, showToast } from "../actions";
import { toastTypes } from "../components/AppToast";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { SCREEN_NAME } from ".";

const addProps = (element: JSX.Element, props?: any) => {
  const Name = element.type;
  return <Name {...props} />;
};

const formSteps: Record<number, JSX.Element> = {
  0: <AmountForm />,
  1: <ReceiverForm />,
  2: <SummaryPage />,
  3: <PaymentForm />,
};

const IntMoneyTransfer = () => {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [cleared, setCleared] = useState(false);
  const { setPage } = useAppNavigation();
  const [loadPayment, setLoadPayment] = useState(false);
  const loadingRef = useRef<any>(null);
  const { getOrderId, limit, history, getClearance, getInternalRates } =
    useAPI();
  const [reference, setReference] = useState(null);
  const [rate, setRate] = useState<number>(NaN);
  const toastRef = useRef<any>(null);
  const {
    state: { user, moneyTransferData },
    dispatch,
  } = useContext(Store);
  const paymentRef = useRef<any>(null);
  const [activeStep, setActiveStep] = useState(
    moneyTransferData?.activeStep || 0
  );
  const initialValues: TransferModel = {
    dollarAmount: moneyTransferData?.dollarAmount || 0,
    cedisAmount: moneyTransferData?.cedisAmount || 0,
    senderFName: moneyTransferData?.senderFName || user?.attributes?.given_name,
    senderLName:
      moneyTransferData?.senderLName ||
      user?.attributes?.family_name ||
      user?.username,
    senderEmail: moneyTransferData?.senderEmail || user?.attributes?.email,
    senderPhone:
      moneyTransferData?.senderPhone || user?.attributes?.phone_number,
    senderCountry: moneyTransferData?.senderCountry || "Ghana",
    receiverFName: moneyTransferData?.receiverFName || "",
    receiverLName: moneyTransferData?.receiverLName || "",
    receiverEmail: moneyTransferData?.receiverEmail || "",
    receiverPhone: moneyTransferData?.receiverPhone || "",
    receiverCountry: moneyTransferData?.receiverCountry || countries[0].name,
    receiveMethod:
      moneyTransferData?.receiveMethod || ReceiveMethod.mobileMoney,
    receiverAccountName: moneyTransferData?.receiverAccountName || "",
    receiverAccountNumber: moneyTransferData?.receiverAccountNumber || "",
    receiveChannel:
      moneyTransferData?.receiveChannel || TelcomProviders[0].value,
    paymentMethod:
      moneyTransferData?.paymentMethod || paymentMethods.creditCard,
    serviceCharge: 0,
    totalAmount: 0,
    rate: moneyTransferData?.rate || 0,
    authUsername: moneyTransferData?.authUsername || user?.username,
    authEmail: moneyTransferData?.authEmail || user?.attributes?.email,
    modeOfPayment: "mobileMoneyTransfer",
  };
  const validationSchema = Yup.object().shape({
    dollarAmount: Yup.number().min(1).required("Amount is required"),
    cedisAmount: Yup.number().min(1).required("Amount is required"),
    senderFName: Yup.string().trim().min(1).required("First Name is required"),
    senderLName: Yup.string().trim().min(1).required("Last Name is required"),
    senderCountry: Yup.string().trim().min(1).required("Country is required"),
    senderPhone: Yup.string().trim().min(10),
    senderEmail: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    receiverFName: Yup.string()
      .trim()
      .min(1)
      .required("First Name is required"),
    receiverLName: Yup.string().trim().min(1).required("Last Name is required"),
    receiverCountry: Yup.string().trim().min(1).required("Country is required"),
    receiverAccountName: Yup.string().trim().min(1),
    receiverAccountNumber: Yup.string()
      .trim()
      .min(10)
      .required("Phone number is required"),
    receiverEmail: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    receiveMethod: Yup.string().required("Receive method is required"),
    paymentMethod: Yup.string().required("Payment method is required"),
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (formData) => {
      values.receiverAccountName = `${values.receiverFName} ${values.receiverLName}`;
      values.receiverPhone = values.receiverAccountNumber;
      try {
        const isLimitResponse = isLimit(
          values.dollarAmount,
          limit,
          history,
          "mobileMoneyTransfer",
          limitPaymentMethods[values.paymentMethod]
        );
        if (isLimitResponse.isLimit) {
          dispatch(
            showToast({
              show: true,
              message: `You have exceeded your ${isLimitResponse.variant} limit for ${values.paymentMethod}. You can only send $${isLimitResponse.leftOver}`,
              ref: toastRef,
              type: toastTypes.error,
            })
          );
        }
        setLoadPayment(true);
        handlePay();
        // console.log(formData);
      } catch (ex: any) {
        console.log(ex.message);
      }
    },
  });

  const getorderID = useCallback(
    async (paymentMethod: string = paymentMethods.creditCard) => {
      try {
        const response = await getOrderId(paymentMethod);
        const { kudiexOrderId } = response;
        setReference(kudiexOrderId);
      } catch (error) {
        console.log(error);
      }
    },
    [getOrderId]
  );

  const calculateTotalTransactionAmount = useCallback(() => {
    const sum = history?.reduce(
      (partialSum: number, a: any) => partialSum + +a.dollarAmount,
      0
    );
    return sum?.toFixed(2);
  }, [history]);

  const isCleared = useCallback(async () => {
    try {
      const response = await getClearance(user.username);
      if (Object.keys(response).length > 0) setCleared(true);
      //set cleared to true
      // if cleared is true, continue, else get send user to
    } catch (error) {
      console.log(error);
    }
  }, [getClearance, user]);

  const getRate = useCallback(async () => {
    openLoader(loadingRef);
    try {
      const response = await getInternalRates();
      const { exchangeRates } = response;
      setRate(exchangeRates);
      closeLoader(loadingRef);
    } catch (error) {
      closeLoader(loadingRef);
    }
  }, [getInternalRates]);

  const isLimitExceeded = useCallback(
    (dollarAmount?: number) => {
      const isLimitResponse = isLimit(
        dollarAmount ? dollarAmount : values.dollarAmount,
        limit,
        history,
        "payWith",
        limitPaymentMethods[values.paymentMethod]
      );
      if (isLimitResponse.isLimit) {
        dispatch(
          showToast({
            show: true,
            message: limitMessage(
              isLimitResponse.variant,
              values.paymentMethod,
              isLimitResponse.leftOver
            ),
            ref: toastRef,
            type: toastTypes.error,
          })
        );
      }

      return isLimitResponse;
    },
    [dispatch, history, limit, values]
  );

  const saveDataInMemory = () => {
    dispatch(saveMoneyTransferData({ ...values, activeStep: activeStep }));
  };

  const handleGoToNext = () => {
    if (validateFormSteps()) {
      const newStep = activeStep + 1;
      setActiveStep(newStep);
      handleCalculateServiceCharge();
      saveDataInMemory();
    }
  };
  const handleGoToPrev = () => {
    const newStep = activeStep - 1;
    setActiveStep(newStep);
    saveDataInMemory();
  };
  const handlePay = () => {
    // setPage(SCREEN_NAME.pay);
    getorderID(values.paymentMethod);
    openLoader(loadingRef);
    paymentRef.current.openSheet();
    closeLoader(loadingRef);
  };

  const handleCalculateServiceCharge = (rate: number = 0.03) => {
    const overflow =
      Math.ceil(Number(values.dollarAmount)) - Number(values.dollarAmount);
    // in order not to overcharge a customer, we floor the service charge and add the amount left to round the actual payment to a whole number
    const serviceCharge =
      +Math.floor(rate * Number(values.dollarAmount)).toFixed(2) + overflow;
    // const serviceCharge = +rate * +Number(values.dollarAmount).toFixed(2);
    const totalAmount: number = +serviceCharge + +values.dollarAmount;
    setFieldValue("totalAmount", totalAmount.toFixed(2));
    setFieldValue("serviceCharge", serviceCharge.toFixed(2));
    return serviceCharge;
  };

  const validateFormSteps = () => {
    if (activeStep === 0) {
      // expected to return true if the step is invalid
      const valueValid = values.cedisAmount && values.dollarAmount;
      const responseValid =
        Boolean(touched.cedisAmount && errors.cedisAmount) &&
        Boolean(touched.dollarAmount && errors.dollarAmount);
      if (valueValid) return valueValid;
      return Boolean(valueValid && !responseValid);
    }
    if (activeStep === 1) {
      // expected to return true if the step is invalid
      const valueValid =
        values.receiverFName &&
        values.receiverLName &&
        values.receiverEmail &&
        values.receiverCountry &&
        // values.receiverAccountName &&
        values.receiverAccountNumber &&
        values.receiveMethod &&
        values.receiveChannel;
      const responseValid =
        Boolean(touched.receiverFName && errors.receiverFName) &&
        Boolean(touched.receiverLName && errors.receiverLName) &&
        Boolean(touched.receiverEmail && errors.receiverEmail) &&
        Boolean(touched.receiverCountry && errors.receiverCountry) &&
        Boolean(touched.receiveChannel && errors.receiveChannel) &&
        Boolean(touched.receiveMethod && errors.receiveMethod) &&
        // Boolean(touched.receiverAccountName && errors.receiverAccountName) &&
        Boolean(touched.receiverAccountNumber && errors.receiverAccountNumber);
      if (valueValid) return valueValid;
      return Boolean(valueValid && !responseValid);
    }
    return true;
  };

  useEffect(() => {
    getRate();
    handleCalculateServiceCharge();
    isLimitExceeded();
    isCleared();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (calculateTotalTransactionAmount() >= 200 && !cleared)
      setOpenInfoDialog(true);
  }, [calculateTotalTransactionAmount, cleared, setOpenInfoDialog]);
  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          // p: 2,
          gap: 3,
          width: "100%",
        }}
      >
        <AppStepper activeStep={activeStep} />
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            gap: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {addProps(formSteps[activeStep], {
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              ErrorMessage,
              setActiveStep,
              setFieldValue,
              handleCalculateServiceCharge,
              getorderID,
              rate,
              moneyTransferData,
              isLimitExceeded,
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 5,
              width: "100%",
            }}
          >
            {activeStep > 0 && (
              <AppButton fullWidth onClick={handleGoToPrev}>
                Previous
              </AppButton>
            )}
            {activeStep < 3 && (
              <AppButton fullWidth onClick={handleGoToNext}>
                Next
              </AppButton>
            )}
            {activeStep === 3 && (
              <IsLoadingAppButton
                fullWidth
                type="submit"
                loading={isSubmitting}
              >
                Pay
              </IsLoadingAppButton>
            )}
          </Box>
        </Box>
        <AppBottomSheet ref={paymentRef}>
          <Stack
            sx={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              gap: 3,
              mt: 5,
            }}
          >
            <Card
              sx={{
                width: 310,
                borderRadius: 5,
                height: 150,
                backgroundColor: "primary.main",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                    color: "white",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    Payment Summary
                  </Typography>
                  <Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>Subtotal</Typography>
                      <Typography>${values.dollarAmount}</Typography>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }}>Processing</Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        ${values.serviceCharge}
                      </Typography>
                    </Stack>
                    <Divider sx={{ border: "2px solid white", my: 1 }} />
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }}>Total</Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        ${values.totalAmount}
                      </Typography>
                    </Stack>
                    <Divider sx={{ border: "2px solid white" }} />
                    <Divider sx={{ border: "2px solid white" }} />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            {loadPayment &&
              values.paymentMethod === paymentMethods.creditCard && (
                <SquarePaymentGateWay data={values} reference={reference} />
              )}
          </Stack>
        </AppBottomSheet>
        <Dialog open={openInfoDialog} maxWidth={"sm"}>
          <DialogContent>
            <Stack gap={3}>
              <Typography fontSize={20}>
                You have exceeded your transaction limit, you need to complete
                verification before you can make more transactions
              </Typography>
              <AppButton onClick={() => setPage(SCREEN_NAME.settings)}>
                Verify
              </AppButton>
            </Stack>
          </DialogContent>
        </Dialog>
        <LoadingModal ref={loadingRef} />
      </Stack>
    </>
  );
};

export default IntMoneyTransfer;
