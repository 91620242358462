import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppButton from "../components/AppButton";
import { SecureDataKeys, useAuthentication } from "../hooks/useAuthentication";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { SCREEN_NAME } from ".";
import LoadingModal from "../components/LoadingModal";
import { useContext, useRef } from "react";
import { toastTypes } from "../components/AppToast";
import { appLocalStorage } from "../utils/AppLocalStorage";
import { showToast } from "../actions";
import { Store } from "../store";

const VerificationSuccess = () => {
  const { dispatch } = useContext(Store);
  const loadingRef = useRef<any>(null);
  const toastRef = useRef<any>(null);
  const { setPage } = useAppNavigation();
  const {
    authProvider: { signIn },
  } = useAuthentication();
  const handleLogin = async () => {
    openLoader();
    try {
      const credentials: any = appLocalStorage.getItem(
        SecureDataKeys.credentials
      );
      const [username, password] = JSON.parse(credentials);
      const response: any = await signIn({ username, password });
      if (response) {
        closeLoader();
        setPage(SCREEN_NAME.dashboard);
      }
    } catch (ex) {
      closeLoader();
      dispatch(
        showToast({
          show: true,
          message: "unable to login",
          ref: toastRef,
          type: toastTypes.error,
        })
      );
      console.log(ex);
    }
  };

  const openLoader = () => loadingRef.current && loadingRef.current.open();
  const closeLoader = () => loadingRef.current && loadingRef.current.close();
  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography
        fontSize={30}
        textAlign={"center"}
        fontWeight={"bold"}
        color={"green"}
      >
        Registration Success
      </Typography>
      <Stack
        sx={{
          width: "100%",
          // height: "70%",
          gap: 5,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 200 }} color="success" />
        <Typography textAlign={"center"}>
          A link ("VerifyMe') has been sent to your email/sms for your ID verification.
        </Typography>
        <AppButton onClick={handleLogin}>Login</AppButton>
      </Stack>
      <LoadingModal ref={loadingRef} />
    </Stack>
  );
};

export default VerificationSuccess;
