import { Avatar, FormControl, Stack, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";
import LoadingModal from "../components/LoadingModal";
import OtpInput from "../components/OtpInput";
import PasswordIcon from "@mui/icons-material/Password";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { SCREEN_NAME } from ".";
import { Store } from "../store";
import { useAuthentication } from "../hooks/useAuthentication";
import { toastTypes } from "../components/AppToast";
import { OTPScreenMode } from "./Signup";
import { clearQuery, saveQuery, showToast } from "../actions";

const Otp = () => {
  const {
    state: { param },
    dispatch,
  } = useContext(Store);
  const {
    authProvider: { confirmSignUp },
  } = useAuthentication();
  const toastRef = useRef<any>(null);
  const { setPage } = useAppNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<any>(null);
  const onComplete = (otp: string) => {
    setIsLoading(true);
    openLoader();
    if (param.otpModeScreen === OTPScreenMode.SignUp) {
      confirmSignUp({ username: param.username, otpCode: otp })
        .then((result: any) => {
          setIsLoading(false);
          closeLoader();
          dispatch(clearQuery());
          setPage(SCREEN_NAME.verificationSuccess);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          dispatch(
            showToast({
              show: true,
              message: "something went wrong",
              ref: toastRef,
              type: toastTypes.error,
            })
          );
        });
    } else {
      setIsLoading(false);
      closeLoader();
      dispatch(
        saveQuery({
          username: param.username,
          otpModeScreen: OTPScreenMode.ForgotPassword,
          otp: otp,
        })
      );
      setPage(SCREEN_NAME.resetPassword);
      // save username and otp to local storage
      // navigate to set password screen
    }
  };
  const openLoader = () => ref.current && ref.current.open();
  const closeLoader = () => ref.current && ref.current.close();
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        p: 2,
        gap: 3,
        width: "100%",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }} alt="Password Icon">
        <PasswordIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        OTP
      </Typography>
      <Typography
        sx={{ textAlign: "center", fontWeight: "semi-bold", fontSize: 12 }}
      >
        Please enter the code sent to your email address/Phone number
      </Typography>
      <FormControl>
        <OtpInput onComplete={onComplete} isLoading={isLoading} />
      </FormControl>
      <LoadingModal ref={ref} />
    </Stack>
  );
};

export default Otp;
