import { AuthLoadingStatus, SIGN_IN } from "../actions/authentication";
import { actions } from "../actions";
import { SecureStorageKeys } from ".";
import { appLocalStorage } from "../utils/AppLocalStorage";
import { SecureDataKeys } from "../hooks/useAuthentication";

export function reducer(state: any, action: any) {
  switch (action.type) {
    case actions.darkModeOn:
      return { ...state, darkMode: true };
    case actions.darkModeOff:
      return { ...state, darkMode: false };
    case actions.changePage:
      action.payload && state.pageHistory.push(action.payload);
      appLocalStorage.setItem(
        SecureStorageKeys.pageHistory,
        JSON.stringify(state.pageHistory)
      );
      return {
        ...state,
        currentPage: action.payload,
      };
    case actions.auth:
      return { ...state, isAuthenticated: action.payload };
    case actions.saveUser:
      return { ...state, user: action.payload };
    case actions.goBack:
      state.pageHistory.pop();
      appLocalStorage.setItem(
        SecureStorageKeys.pageHistory,
        JSON.stringify(state.pageHistory)
      );
      const activePage = state.pageHistory[state.pageHistory.length - 1];
      appLocalStorage.setItem(SecureStorageKeys.page, activePage);
      return { ...state, currentPage: activePage };
    case SIGN_IN.success:
      appLocalStorage.setItem(
        SecureDataKeys.accessToken,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        user: { ...action.payload },
      };

    case SIGN_IN.failure:
      return {
        ...state,
        user: null,
      };

    case SIGN_IN.logout:
      return {
        ...state,
        user: null,
      };

    case SIGN_IN.addRoles:
      return {
        ...state,
        user: { ...state.user, roles: action.payload },
      };

    case AuthLoadingStatus:
      return {
        ...state,
        auth: {
          isLoading: action.payload,
        },
      };
    case actions.setQueryParam:
      return {
        ...state,
        param: action.payload,
      };
    case actions.clearQueryParam:
      return {
        ...state,
        param: action.payload,
      };
    case actions.showToast:
      return {
        ...state,
        toast: action.payload,
      };
    case actions.saveMoneyTransferData:
      return {
        ...state,
        moneyTransferData: action.payload,
      };
    case actions.removeMoneyTransferData:
      return {
        ...state,
        moneyTransferData: null,
      };
    case actions.setFromCurrency:
      return {
        ...state,
        currencyState: {
          ...state.currencyState,
          fromCurrency: action.payload,
        },
      };
    case actions.setToCurrency:
      return {
        ...state,
        currencyState: {
          ...state.currencyState,
          toCurrency: action.payload,
        },
      };
    default:
      return state;
  }
}
