import { Box } from "@mui/material";
import { useContext, useEffect } from "react";
import { Store } from "../store";
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import HomePage from "./Home";
import SideNav from "../components/SideNav";
import LogInPage from "./Login";
import SignUpPage from "./Signup";
import Otp from "./Otp";
import PaymentPage from "./Pay";
import DashboardPage from "./Dashboard";
import { activateDarkMode, deActivateDarkMode } from "../actions";
import TransactionHistoryPage from "./TransactionHistory";
import SettingsPage from "./Settings";
import ProfilePage from "./Profile";
import LocalMoneyTransfer from "./LocalMoneyTransfer";
import IntMoneyTransfer from "./IntMoneyTransfer";
import { useAppNavigation } from "../hooks/useAppNavigation";
import VerificationSuccess from "./VerificationSuccess";
import PasswordResetSuccess from "./PasswordResetSuccess";
import ForgotPasswordPage from "./ForgotPassword";
import PasswordResetPage from "./PasswordReset";
import AppToast from "../components/AppToast";
import PaymentSuccessPage from "./PaymentSuccess";

export const SCREEN_NAME: any = {
  home: "home",
  login: "login",
  signUp: "signUp",
  otp: "otp",
  intMoneyTransfer: "intMoneyTransfer",
  pay: "pay",
  dashboard: "dashboard",
  history: "history",
  settings: "settings",
  profile: "profile",
  localMoneyTransfer: "localMoneyTransfer",
  verificationSuccess: "verificationSuccess",
  passwordResetSuccess: "passwordResetSuccess",
  paymentSuccess: "paymentSuccess",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
};
export const SCREENS: any = {
  home: <HomePage />,
  login: <LogInPage />,
  signUp: <SignUpPage />,
  otp: <Otp />,
  intMoneyTransfer: <IntMoneyTransfer />,
  pay: <PaymentPage />,
  dashboard: <DashboardPage />,
  history: <TransactionHistoryPage />,
  settings: <SettingsPage />,
  profile: <ProfilePage />,
  localMoneyTransfer: <LocalMoneyTransfer />,
  verificationSuccess: <VerificationSuccess />,
  passwordResetSuccess: <PasswordResetSuccess />,
  paymentSuccess: <PaymentSuccessPage />,
  forgotPassword: <ForgotPasswordPage />,
  resetPassword: <PasswordResetPage />,
};

function renderSwitch(currentPage: any) {
  switch (currentPage) {
    case SCREEN_NAME.home:
      return <>{SCREENS.home}</>;
    case SCREEN_NAME.login:
      return <>{SCREENS.login}</>;
    case SCREEN_NAME.signUp:
      return <>{SCREENS.signUp}</>;
    case SCREEN_NAME.otp:
      return <>{SCREENS.otp}</>;
    case SCREEN_NAME.intMoneyTransfer:
      return <>{SCREENS.intMoneyTransfer}</>;
    case SCREEN_NAME.localMoneyTransfer:
      return <>{SCREENS.localMoneyTransfer}</>;
    case SCREEN_NAME.pay:
      return <>{SCREENS.pay}</>;
    case SCREEN_NAME.dashboard:
      return <>{SCREENS.dashboard}</>;
    case SCREEN_NAME.history:
      return <>{SCREENS.history}</>;
    case SCREEN_NAME.settings:
      return <>{SCREENS.settings}</>;
    case SCREEN_NAME.profile:
      return <>{SCREENS.profile}</>;
    case SCREEN_NAME.verificationSuccess:
      return <>{SCREENS.verificationSuccess}</>;
    case SCREEN_NAME.passwordResetSuccess:
      return <>{SCREENS.passwordResetSuccess}</>;
    case SCREEN_NAME.forgotPassword:
      return <>{SCREENS.forgotPassword}</>;
    case SCREEN_NAME.resetPassword:
      return <>{SCREENS.resetPassword}</>;
    case SCREEN_NAME.paymentSuccess:
      return <>{SCREENS.paymentSuccess}</>;

    default:
      return <>{SCREENS.home}</>;
  }
}

const Index = () => {
  // const [open, setOpen] = useState(false);
  const { setPage } = useAppNavigation();
  const { state, dispatch } = useContext(Store);
  const { darkMode, currentPage, user, toast } = state;
  const switchThemeHandler = function () {
    if (!darkMode) {
      dispatch(activateDarkMode(true));
    } else {
      dispatch(deActivateDarkMode(false));
    }
  };
  const handleSetScreen = (item: any) => {
    // setScreen(item);
  };
  useEffect(() => {
    if (!user) setPage(currentPage);
    const x = window.document.querySelectorAll('*[class^="acOpenButton"]')[0];
    const className = x?.classList;
    const chatBotFab =
      className && document.querySelector<HTMLElement>(`.${className[0]}`);
    if (chatBotFab && !user) {
      chatBotFab.style.display = "none";
    }
    if (chatBotFab && user && currentPage === SCREEN_NAME.settings) {
      chatBotFab.style.display = "block";
    }
    // setPage(SCREEN_NAME.login);
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (toast.show) {
      toast.ref.current.openSheet();
    }
    // eslint-disable-next-line
  }, [toast.show]);

  // const toggleDrawer = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "100vh",
          backgroundImage: `${
            darkMode
              ? "url('images/bg-dark.svg')"
              : "url('images/bg-primary.svg')"
          }`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",

          // flexDirection: "column",
          position: "relative",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "rgba(0,0,0,.6);",
            position: "absolute",
            top: 0,
            left: 0,
            px: {
              xs: 3,
              md: 10,
            },
            // pb: {
            //   xs: 15,
            //   md: 10,
            // },
            pt: {
              xs: 1,
              md: 3,
            },
            color: "text.primary",
          }}
        >
          <Box>
            <TopNav
              switchThemeHandler={switchThemeHandler}
              showBackButton={
                currentPage === SCREEN_NAME.intMoneyTransfer ||
                currentPage === SCREEN_NAME.profile ||
                currentPage === SCREEN_NAME.localMoneyTransfer
                  ? true
                  : false
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pt: 15,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "background.default",
                width: {
                  xs: 340,
                  sm: 340,
                  md: 500,
                  lg: 500,
                },
                // maxWidth: 500,
                p: 3,
                borderRadius: "15px",
                mb: 10,
              }}
            >
              {renderSwitch(currentPage)}
            </Box>
          </Box>
          {user && (
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
            >
              <BottomNav handleSetScreen={handleSetScreen} />
            </Box>
          )}
          {user && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <SideNav handleSetScreen={handleSetScreen} />
            </Box>
          )}
        </Box>
        {/* <Fab
          color="primary"
          aria-label="chat"
          sx={{ position: "absolute", bottom: 10, right: 10 }}
          onClick={() => toggleDrawer()}
        >
          <ChatOutlined />
        </Fab> */}
      </Box>
      <AppToast ref={toast.ref} type={toast.type} message={toast.message} />
      {/* <Drawer
        anchor={"bottom"}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 64px)",
            top: 64,
          },
        }}
      >
        <Chat handleClose={() => setOpen(false)} />
      </Drawer> */}
    </>
  );
};

export default Index;
