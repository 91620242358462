import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppListItem from "../components/AppListItem";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import useAPI from "../hooks/useApi";
import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import LoadingModal from "../components/LoadingModal";
import CloseIcon from "@mui/icons-material/Close";
import SmallText from "../components/SmallText";
import AppButton from "../components/AppButton";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { Store } from "../store";
import { saveMoneyTransferData } from "../actions";
import { SCREEN_NAME } from ".";
import { paymentMethods } from "./LocalMoneyTransfer";

const CustomRadioFormControl = (props: any) => {
  const { paymentMethod, label } = props;
  return (
    <FormControlLabel
      value={paymentMethod}
      control={
        <Radio
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 11,
            },
          }}
        />
      }
      label={label}
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: 11,
          fontWeight: "bold",
        },
      }}
    />
  );
};

const TransactionHistoryPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [totalTransactionAmount, setTotalTransactionAmount] =
    useState<number>(0);
  // const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef<any>(null);
  const { history } = useAPI();
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [currentHistory, setCurrentHistory] = useState<any | null>(null);
  const { setPage } = useAppNavigation();
  const { dispatch } = useContext(Store);
  const [filter, setFilter] = useState("all");

  const calculateTotalTransactionAmount = (his: any) => {
    const sum = his?.reduce(
      (partialSum: number, a: any) => partialSum + +a.dollarAmount,
      0
    );
    setTotalTransactionAmount(sum?.toFixed(2));
  };

  useEffect(() => {
    // setIsLoading(true);
    openLoader();
    calculateTotalTransactionAmount(history);
    closeLoader();
    // eslint-disable-next-line
  }, [history]);
  const openLoader = () => loadingRef.current && loadingRef.current.open();
  const closeLoader = () => loadingRef.current && loadingRef.current.close();
  const handleCloseDialog = () => {
    setOpenHistoryDialog(false);
  };

  const handleOpenDialog = (his: any) => {
    setOpenHistoryDialog(true);
    setCurrentHistory(his);
  };
  const handleResend = (historyData: any) => {
    const data = {
      dollarAmount: +historyData?.dollarAmount,
      cedisAmount: historyData?.cedisAmount,
      senderFName: historyData?.senderFName,
      senderLName: historyData?.senderLName,
      senderEmail: historyData?.senderEmail,
      senderPhone: historyData?.senderPhone,
      senderCountry: historyData?.senderCountry,
      receiverFName: historyData?.receiverFName,
      receiverLName: historyData?.receiverLName,
      receiverEmail: historyData?.receiverEmail,
      receiverPhone: historyData?.receiverAccountNumber,
      receiverCountry: historyData?.receiverCountry,
      receiveMethod: historyData?.receiveMethod,
      receiverAccountName: historyData?.receiverAccountName,
      receiverAccountNumber: historyData?.receiverAccountNumber,
      receiveChannel: historyData?.receiveChannel,
      paymentMethod: historyData?.paymentMethod,
      serviceCharge: 0,
      totalAmount: 0,
      rate: historyData?.rate,
      authUsername: historyData?.authUsername,
      authEmail: historyData?.authEmail,
      modeOfPayment: historyData.modeOfPayment,
    };
    // console.log(data);
    dispatch(saveMoneyTransferData(data));
    setPage(
      historyData.modeOfPayment === "mobileMoneyTransfer"
        ? SCREEN_NAME.intMoneyTransfer
        : SCREEN_NAME.localMoneyTransfer
    );
  };
  const sortedData = useCallback((historyData: any) => {
    return historyData?.sort((a: any, b: any) =>
      new Date(a.pendingDate) < new Date(b.pendingDate) ? 1 : -1
    );
  }, []);
  const filteredHistory: [] = useMemo(() => {
    const historyFiltered = history?.filter(
      (h: any) => h?.paymentMethod === filter
    );
    return historyFiltered?.length
      ? sortedData(historyFiltered)
      : sortedData(history);
  }, [filter, history, sortedData]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((event.target as HTMLInputElement).value);
  };

  // console.log(history);
  return (
    <>
      <Stack sx={{ gap: 3, width: "100%" }}>
        <Stack sx={{ flexDirection: "column", width: "100%", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Transaction History
            </Typography>
          </Box>
          <Card
            sx={{
              // width: 350,
              borderRadius: 5,
              height: 100,
              backgroundColor: "primary.main",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  gap: 2,
                }}
              >
                <AnalyticsIcon sx={{ fontSize: 70, color: "white" }} />
                <Stack>
                  <Typography
                    color={"white"}
                    sx={{ fontSize: 12, width: "100%" }}
                  >
                    Total Money Out
                  </Typography>
                  <Typography
                    color={"white"}
                    sx={{ fontSize: 25, width: "100%", fontWeight: "bold" }}
                  >
                    ${totalTransactionAmount}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          {filteredHistory?.length > 0 && (
            <Stack
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={filter}
                    onChange={handleChangeFilter}
                  >
                    <CustomRadioFormControl
                      paymentMethod={"all"}
                      label={"All"}
                    />
                    <CustomRadioFormControl
                      paymentMethod={paymentMethods.paypal}
                      label={"PayPal"}
                    />
                    <CustomRadioFormControl
                      paymentMethod={paymentMethods.cashApp}
                      label={"Cash App"}
                    />
                    <CustomRadioFormControl
                      paymentMethod={paymentMethods.creditCard}
                      label={"Credit Card"}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Stack>
          )}
          <List
            sx={{
              width: "100%",
              // maxWidth: 350,
              bgcolor: "background.paper",
              mt: 2,
            }}
          >
            <Stack sx={{ gap: 3 }}>
              {filteredHistory?.map((item: any, index: number) => (
                <Box
                  key={index}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleOpenDialog(item)}
                >
                  <AppListItem history={item} />
                  <Divider sx={{ mt: 1 }} />
                </Box>
              ))}
              {filteredHistory?.length === 0 && (
                <Typography>You have not made any transaction yet</Typography>
              )}
            </Stack>
          </List>
        </Stack>
        <LoadingModal ref={loadingRef} />
      </Stack>
      <Dialog open={openHistoryDialog} fullScreen={fullScreen} maxWidth={false}>
        <DialogTitle>Detail</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack gap={3}>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Order ID</Typography>
                <SmallText>{currentHistory?.orderId}</SmallText>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                <Typography>{currentHistory?.pendingDate}</Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Amount($)</Typography>
                <Typography>{currentHistory?.dollarAmount}</Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Mode of Payment
                </Typography>
                <Typography>{currentHistory?.modeOfPayment}</Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Payment Method
                </Typography>
                <Typography>{currentHistory?.paymentMethod}</Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Receiver Name
                </Typography>
                <Typography>
                  {currentHistory?.receiverFName}{" "}
                  {currentHistory?.receiverLName}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Receiver Email
                </Typography>
                <Typography>{currentHistory?.receiverEmail}</Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack gap={2}>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Receiver Phone Number
                </Typography>
                <Typography>{currentHistory?.receiverPhoneNumber}</Typography>
              </Stack>
              <Divider />
            </Stack>
            <AppButton fullWidth onClick={() => handleResend(currentHistory)}>
              Resend
            </AppButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionHistoryPage;
