import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppButton from "../components/AppButton";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { SCREEN_NAME } from ".";

const PasswordResetSuccess = () => {
  const { setPage } = useAppNavigation();
  const handleGoToLoginPage = () => {
    setPage(SCREEN_NAME.login);
  };
  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography
        fontSize={30}
        textAlign={"center"}
        fontWeight={"bold"}
        color={"green"}
      >
        Password Reset Successful
      </Typography>
      <Stack
        sx={{
          width: "100%",
          gap: 5,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 200 }} color="success" />
        <Typography textAlign={"center"}>
          You password has been updated. You can now login with the new
          password.
        </Typography>
        <AppButton onClick={handleGoToLoginPage}>Login</AppButton>
      </Stack>
    </Stack>
  );
};

export default PasswordResetSuccess;
