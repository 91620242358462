import { useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/material";

const StyledOtpTextField = styled(MuiOtpInput)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "15px",
    borderColor: theme.palette.action.disabled,
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.action.hover,
  },
  "& .MuiInputLabel-root": {
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.text.disabled,
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

type OtpInputProps = {
  isLoading?: boolean;
  onComplete: (otp: string) => void;
};

const OtpInput = ({ onComplete }: OtpInputProps) => {
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp.length === 6) {
      onComplete(otp);
    }
    // eslint-disable-next-line
  }, [otp]);

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  return (
    <StyledOtpTextField
      autoFocus
      length={6}
      sx={{ width: "320px" }}
      value={otp}
      onChange={handleChange}
      TextFieldsProps={{
        type: "number",
        inputProps: {
          inputMode: "numeric",
          pattern: "[0-9]*",
        },
        size: "medium",
      }}
    />
  );
};

export default OtpInput;
