import {
  Box,
  Fab,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import AppTextField from "../../components/AppTextField";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useEffect, useRef } from "react";
import LoadingModal from "../../components/LoadingModal";

const AmountForm = (props: any) => {
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    rate,
    isLimitExceeded,
  } = props;
  // const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef<any>(null);

  useEffect(() => {
    setFieldValue(
      "cedisAmount",
      (rate * Number(values.dollarAmount)).toFixed(2)
    );
    setFieldValue("rate", rate);
    // eslint-disable-next-line
  }, [rate]);

  const handleOnDollarChange = (event: any) => {
    handleChange(event);
    const val = event.currentTarget.value;
    setFieldValue("cedisAmount", (rate * Number(val)).toFixed(2));
    setFieldValue("rate", rate);
    isLimitExceeded(+event.currentTarget.value);
  };

  const handleOnCedisChange = (event: any) => {
    handleChange(event);
    const val = event.currentTarget.value;
    const dollarAmount = +(Number(val) / rate).toFixed(2);
    setFieldValue("dollarAmount", dollarAmount);
    setFieldValue("rate", rate);
    isLimitExceeded(dollarAmount);
  };

  return (
    <>
      <Stack
        gap={2}
        width={"inherit"}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <AppTextField
            autoFocus
            fullWidth
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD</InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              style: { fontSize: 20 },
            }}
            id="dollarAmount"
            name="dollarAmount"
            onChange={handleOnDollarChange}
            onBlur={handleBlur}
            value={values.dollarAmount || ""}
            error={Boolean(touched.dollarAmount && errors.dollarAmount)}
            showErrorMessage={Boolean(
              touched.dollarAmount && errors.dollarAmount
            )}
            errorMessage={errors.dollarAmount}
          />
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Fab
              aria-label="convert"
              sx={{
                display: "flex",
                justifySelf: "flex-start",
              }}
              size="small"
              color="primary"
            >
              <SwapVertIcon />
            </Fab>
          </Box>
          <AppTextField
            type="text"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GHS</InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: "numeric",
              style: { fontSize: 20 },
            }}
            id="cedisAmount"
            name="cedisAmount"
            onChange={handleOnCedisChange}
            onBlur={handleBlur}
            value={values.cedisAmount || ""}
            error={Boolean(touched.cedisAmount && errors.cedisAmount)}
            showErrorMessage={Boolean(
              touched.cedisAmount && errors.cedisAmount
            )}
            errorMessage={errors.cedisAmount}
          />
        </Box>
        <Box>
          <Typography color={"grey"} sx={{ fontSize: 12, textAlign: "left" }}>
            Indicative exchange rate
          </Typography>
          {rate ? (
            <Typography
              sx={{ fontSize: 20, textAlign: "left", fontWeight: "bold" }}
            >
              $1 ≈ {rate.toFixed(2)}GHS
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          )}
        </Box>
        <LoadingModal ref={loadingRef} />
      </Stack>
    </>
  );
};

export default AmountForm;
