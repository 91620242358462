import {
  Box,
  Card,
  CardContent,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import SmallText from "../../components/SmallText";

type SummaryDataProps = {
  name: string;
  value: string;
};

const Summary = (props: any) => {
  const { header, goToEdit, children } = props;
  return (
    <Stack sx={{ width: "inherit" }}>
      <Card sx={{ width: "inherit", borderRadius: 5 }}>
        <CardContent>
          <Stack sx={{ gap: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                {header}
              </Typography>
              {goToEdit && (
                <Link onClick={goToEdit} sx={{ cursor: "pointer" }}>
                  Edit
                </Link>
              )}
            </Box>
            {children}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

const SummaryData = ({ name, value }: SummaryDataProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <SmallText fontWeight="bold">{name}</SmallText>
        <SmallText>{value}</SmallText>
      </Box>
    </>
  );
};

const SummaryPage = (props: any) => {
  const { values, setActiveStep } = props;
  return (
    <>
      <Stack sx={{ gap: 2 }}>
        <Summary header="Amount" goToEdit={() => setActiveStep(0)}>
          <SummaryData name={"Person Receives"} value={values.cedisAmount} />
        </Summary>
        <Summary header="Receiver's Info" goToEdit={() => setActiveStep(1)}>
          <SummaryData name={"First Name"} value={values.receiverFName} />
          <SummaryData name={"Last Name"} value={values.receiverLName} />
          <SummaryData
            name={"Phone Number"}
            value={values.receiverAccountNumber}
          />
          <SummaryData name={"Payment Channel"} value={values.receiveChannel} />
          <SummaryData name={"Payment Provider"} value={values.receiveMethod} />
          <SummaryData
            name={"Account Name"}
            value={`${values.receiverFName} ${values.receiverLName}`}
          />
          <SummaryData
            name={"Account Number"}
            value={values.receiverAccountNumber}
          />
        </Summary>
        <Summary header="Transfer Details">
          <SummaryData name={"Sending"} value={`$${values.dollarAmount}`} />
          <SummaryData
            name={"Receiver Gets"}
            value={`${values.cedisAmount} GHS`}
          />
          <SummaryData
            name={"Exchange rate"}
            value={`$1 ≈ ${values.rate.toFixed(2)} GHS`}
          />
          <SummaryData
            name={"Transfer fees"}
            value={`$${values.serviceCharge}`}
          />
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight="bold">{"Total"}</Typography>
            <Typography fontWeight="bold">{`$${values.totalAmount}`}</Typography>
          </Box>
        </Summary>
      </Stack>
    </>
  );
};

export default SummaryPage;
