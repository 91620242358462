import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Person2Icon from "@mui/icons-material/Person2";
import Typography from "@mui/material/Typography";
import AppTextField from "../components/AppTextField";
// import { useAppNavigation } from "../hooks/useAppNavigation";
// import useAuthentication from "../hooks/useAuthentication";
import { useContext, useRef, useState } from "react";
import { toastTypes } from "../components/AppToast";
import { Store } from "../store";
import { useAuthentication } from "../hooks/useAuthentication";
import { showToast } from "../actions";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import theme from "../theme";
import CloseIcon from "@mui/icons-material/Close";
import OtpInput from "../components/OtpInput";
import IsLoadingAppButton from "../components/IsLoadingAppButton";
import { closeLoader, openLoader } from "../utils";
import LoadingModal from "../components/LoadingModal";

export default function ProfilePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { state, dispatch } = useContext(Store);
  const { user } = state;
  const {
    authProvider: {
      updateProfile,
      updateProfileInSession,
      confirmAttributeUpdate,
    },
  } = useAuthentication();
  const toastRef = useRef<any>(null);
  const loadingRef = useRef<any>(null);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const formObject = Object.fromEntries(data.entries());
    const postData = {
      email: formObject.email,
      phone_number: formObject.phoneNumber,
      given_name: formObject.firstName,
      family_name: formObject.lastName,
    };
    try {
      const response = await updateProfile(postData);
      if (!response) {
        dispatch(
          showToast({
            show: true,
            message:
              "There was a problem updating your profile. Try again later or contact admin",
            ref: toastRef,
            type: toastTypes.error,
          })
        );
      } else {
        if (user.attributes.email === formObject.email) {
          dispatch(
            showToast({
              show: true,
              message:
                "Your profile has been updated successfully. Changes will be effected when you log out and log in again",
              ref: toastRef,
              type: toastTypes.success,
            })
          );
        } else {
          setOpenOtpDialog(true);
        }
        await updateProfileInSession();
      }
    } catch (ex: any) {
      console.log(ex.message);
      dispatch(
        showToast({
          show: true,
          message: "Unable to update profile",
          ref: toastRef,
          type: toastTypes.error,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmEmail = async (verificationCode: string) => {
    openLoader(loadingRef);
    const response = await confirmAttributeUpdate("email", verificationCode);
    if (response) {
      await updateProfileInSession();
      closeLoader(loadingRef);
      handleCloseDialog();
      dispatch(
        showToast({
          show: true,
          message:
            "Your profile has been updated successfully. Changes will be effected when you log out and log in again",
          ref: toastRef,
          type: toastTypes.success,
        })
      );
    }
  };

  const handleCloseDialog = () => {
    setOpenOtpDialog(false);
  };

  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }} alt="Person Icon">
        <Person2Icon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Profile
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, width: "100%" }}
      >
        <AppTextField
          defaultValue={user?.attributes?.given_name}
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="firstName"
          autoFocus
        />
        <AppTextField
          defaultValue={user?.attributes?.family_name}
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="lastName"
        />
        <AppTextField
          defaultValue={user?.attributes?.email}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          type="email"
        />
        <AppTextField
          defaultValue={user?.attributes?.phone_number}
          margin="normal"
          required
          fullWidth
          name="phoneNumber"
          label="Phone Number"
          type="phoneNumber"
          id="phoneNumber"
          autoComplete="phoneNumber"
        />
        <IsLoadingAppButton
          loading={isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Update Profile
        </IsLoadingAppButton>
      </Box>
      {/* <AppToast ref={toastRef} type={toastType} message={toastMessage} /> */}
      <Dialog open={openOtpDialog} fullScreen={fullScreen} maxWidth={false}>
        <DialogTitle textAlign={"center"}>Confirm OTP</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "semi-bold",
              fontSize: 12,
              mb: 5,
            }}
          >
            Please enter the code sent to your new email address/Phone number
          </Typography>
          <OtpInput onComplete={handleConfirmEmail} isLoading={isLoading} />
          <LoadingModal ref={loadingRef} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
