import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import AppTextField from "../components/AppTextField";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { useAuthentication } from "../hooks/useAuthentication";
import { useContext, useEffect, useRef, useState } from "react";
import { toastTypes } from "../components/AppToast";
import { Store } from "../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import IsLoadingAppButton from "../components/IsLoadingAppButton";
import { SCREEN_NAME } from ".";
import { showToast } from "../actions";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OtpInput from "../components/OtpInput";
import LoadingModal from "../components/LoadingModal";
import theme from "../theme";
import { closeLoader, openLoader } from "../utils";

export default function LogInPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [tempUser, setTempUser] = useState(undefined);
  const loadingRef = useRef<any>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { state, dispatch } = useContext(Store);
  const { user } = state;
  const toastRef = useRef<any>(null);
  const { setPage } = useAppNavigation();
  const {
    authProvider: { signIn, confirmSignIn },
  } = useAuthentication();

  const initialValues = {
    username: "",
    password: "",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().strict(true).max(255).trim().required(),
    password: Yup.string().min(6).required(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ username, password }) => {
      try {
        const response: any = await signIn({ username, password });
        if (response.challengeName === "SMS_MFA") {
          setTempUser(response);
          setOpenOtpDialog(true);
        } else {
          if (response) setPage(SCREEN_NAME.dashboard);
        }
      } catch (ex) {
        dispatch(
          showToast({
            show: true,
            message: "Invalid username or password",
            ref: toastRef,
            type: toastTypes.error,
          })
        );
      }
    },
  });

  useEffect(() => {
    if (user?.signInUserSession) {
      setPage(SCREEN_NAME.dashboard);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleGoToSignUpPage = () => setPage(SCREEN_NAME.signUp);
  const handleGoToForgotPasswordPage = () =>
    setPage(SCREEN_NAME.forgotPassword);

  const getOTP = async (verificationCode: string) => {
    openLoader(loadingRef);
    await handleConfirmOTP(tempUser, verificationCode);
  };

  const handleConfirmOTP = async (user: any, verificationCode: string) => {
    setIsLoading(false);
    const response = await confirmSignIn(user, verificationCode);
    console.log(response);
    closeLoader(loadingRef);
    if (response?.signInUserSession) {
      handleCloseDialog();
      setPage(SCREEN_NAME.dashboard);
    }
  };

  const handleCloseDialog = () => {
    setOpenOtpDialog(false);
  };

  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Avatar sx={{ mx: 0, bgcolor: "primary.main" }} alt="Locked Icon">
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Log in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, width: "100%" }}
      >
        <AppTextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.username || ""}
          error={Boolean(touched.username && errors.username)}
        />
        <AppTextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password || ""}
          error={Boolean(touched.password && errors.password)}
        />
        <IsLoadingAppButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          loading={isSubmitting}
        >
          Log In
        </IsLoadingAppButton>
        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={handleGoToForgotPasswordPage}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2" onClick={handleGoToSignUpPage}>
              {"Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openOtpDialog} fullScreen={fullScreen} maxWidth={false}>
        <DialogTitle textAlign={"center"}>Confirm OTP</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "semi-bold",
              fontSize: 12,
              mb: 5,
            }}
          >
            Please enter the code sent to your new email address/Phone number
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <OtpInput onComplete={getOTP} isLoading={isLoading} />
          </Box>
          <LoadingModal ref={loadingRef} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
