import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import Typography from "@mui/material/Typography";
import AppTextField from "../components/AppTextField";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { useAuthentication } from "../hooks/useAuthentication";
import { useContext, useEffect, useRef } from "react";
import { toastTypes } from "../components/AppToast";
import { Store } from "../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import IsLoadingAppButton from "../components/IsLoadingAppButton";
import { SCREEN_NAME } from ".";
import { saveQuery, showToast } from "../actions";
import { OTPScreenMode } from "./Signup";
import { Stack } from "@mui/material";

export default function ForgotPasswordPage() {
  const { state, dispatch } = useContext(Store);
  const { user } = state;
  const toastRef = useRef<any>(null);
  const { setPage } = useAppNavigation();
  const {
    authProvider: { forgotPassword },
  } = useAuthentication();

  const initialValues = {
    username: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().max(255).required(),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ username }) => {
      username = username.trim();
      try {
        await forgotPassword(username);

        dispatch(
          saveQuery({
            username: username,
            otpModeScreen: OTPScreenMode.ForgotPassword,
          })
        );

        setPage(SCREEN_NAME.otp);
      } catch (ex) {
        dispatch(
          showToast({
            show: true,
            message: "unable to send request",
            ref: toastRef,
            type: toastTypes.error,
          })
        );
      }
    },
  });
  useEffect(() => {
    if (user) {
      setPage(SCREEN_NAME.dashboard);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Stack
      sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <EnhancedEncryptionIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 0, width: "100%" }}
      >
        <AppTextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.username || ""}
          error={Boolean(touched.username && errors.username)}
        />
        <IsLoadingAppButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          loading={isSubmitting}
        >
          Send password reset request
        </IsLoadingAppButton>
      </Box>
    </Stack>
  );
}
