import { Avatar, Box, Stack, Typography } from "@mui/material";

const AppListItem = (props: any) => {
  const { history } = props;
  const {
    paymentMethod,
    dollarAmount,
    receiverAccountName,
    pendingDate,
    pendingTransactionSuccess,
    transactioncomplete,
    modeOfPayment,
  } = history || {};
  return (
    <>
      <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Stack sx={{ flexDirection: "row", gap: 2, width: 230 }}>
          <Avatar alt={paymentMethod} src={`images/${paymentMethod}.svg`} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "full",
                }}
              >
                <Typography sx={{ fontSize: 11 }}>
                  {receiverAccountName}
                </Typography>
                <Typography sx={{ fontSize: 11 }}>
                  {modeOfPayment === "mobileMoneyTransfer"
                    ? "Transfer"
                    : "Direct Pay"}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "full",
                }}
              >
                <Typography sx={{ fontSize: 11 }} color={"gray"}>
                  {new Date(pendingDate).toLocaleDateString()}
                </Typography>
                {pendingTransactionSuccess === "completed" ||
                transactioncomplete === "true" ||
                transactioncomplete === true ? (
                  <Typography color={"green"} sx={{ fontSize: 11 }}>
                    complete
                  </Typography>
                ) : (
                  <Typography color={"orange"} sx={{ fontSize: 11 }}>
                    Pending
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography sx={{ fontSize: 11, fontWeight: "bold" }}>
            ${dollarAmount}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default AppListItem;
