import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

type BadgeAvatarsProps = {
  avatarLink: string;
  badgeIcon: any;
  width?: number;
  height?: number;
};

export default function BadgeAvatars({
  avatarLink,
  badgeIcon,
  width,
  height,
}: BadgeAvatarsProps) {
  return (
    <Stack direction="row" spacing={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={badgeIcon}
      >
        <Avatar
          alt="Profile Image"
          src={avatarLink}
          sx={{ width: width, height: height }}
        />
      </Badge>
    </Stack>
  );
}
