import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { Store } from "./store";
import { useContext, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { useAppNavigation } from "./hooks/useAppNavigation";
import { Amplify } from "aws-amplify";
import awsConfig from "./awsConfig";

Amplify.configure(awsConfig);

function App() {
  const { state } = useContext(Store);
  const { setPage, currentPage } = useAppNavigation();
  const { darkMode, user } = state;
  const theme = createTheme({
    palette: {
      primary: {
        main: "#312393",
      },
      secondary: {
        main: "#B0A380",
      },
      mode: darkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    if (user) {
      setPage(currentPage);
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
