export type ToastData = {
  type?: "success" | "error" | "info" | "warning";
  message?: string;
  ref?: any;
  show: boolean;
};

export type User = {
  id: string;
  name: string;
  username: string;
  email: string;
  city: string;
  country: string;
  phoneNumber: string;
};

export type Credentials = {
  username: string;
  password: string;
};

export type SignUpFormData = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  phoneNumber: string;
};

export type VerifyAccountData = {
  username: string;
  otpCode: string;
};
export type CognitoUser = any;

export interface IRequireStore {
  storeProvider: () => any;
}

export enum ReceiveMethod {
  mobileMoney = "Mobile Money",
  bankTransfer = "Bank Transfer",
  airtime = "Airtime",
}

export const momoChannels = ["mtn", "vodafone", "airteltigo"];

export const bankChannels = [
  "Ghana Commercial Bank(GCB)",
  "Zenith Bank(GTB)",
  "Eco Bank",
  "Fidelity Bank",
  "Access Bank",
  "Agricultural Development Bank (ADB)",
  "UniBank Ghana",
  "National Investment Bank (NIB)",
  "Universal Merchant Bank",
  "United Bank of Africa (UBA) Ghana",
  "Ghana International Bank (GHIB)",
  "Citibank NA Ghana",
  "First Atlantic Merchant Bank (FAMB)",
  "Prudential Bank",
  "Energy Bank Ghana",
  "The Trust Bank (TTB)",
  "ARB Apex Bank",
  "CAL Bank",
  "Barclays Bank of Ghana",
  "Societe General Ghana Limited",
  "The Royal Bank Limited",
  "Standard Chartered Bank Ghana Limited",
];

export type TransferModel = {
  dollarAmount: number;
  cedisAmount: number;
  serviceCharge?: number;
  totalAmount: number;
  kudiexCharge?: number;
  receiveMethod?: ReceiveMethod;
  flag?: string;
  rate?: number;
  rateCharge?: number;
  senderFName?: string;
  senderLName?: string;
  senderEmail: string;
  senderPhone: string;
  senderCountry: string;
  receiverFName: string;
  receiverLName: string;
  receiverEmail: string;
  receiverPhone?: string;
  receiverCountry: string;
  paymentMethod: string;
  receiveChannel: string; // MTN, VODAFONE. TIGO, ZENITH BANK, ETC
  receiverAccountName: string; // momo name or bank account name. Not compulsory for airtime transfer method
  receiverAccountNumber: string; // momo number or account number or airtime transfer number
  activeStep?: number;
  authUsername: string;
  authEmail: string;
  modeOfPayment?: "mobileMoneyTransfer" | "payWith";
};
