import { styled, useTheme } from "@mui/material";
import {
  CreditCard,
  PaymentForm,
  CashAppPay,
} from "react-square-web-payments-sdk";
import type * as Square from "@square/web-sdk";
import { closeLoader, getDeviceInfoAndIp, openLoader } from "../../utils";
import useAPI from "../../hooks/useApi";
import { TransferModel } from "../../typing";
import { SCREEN_NAME } from "../../pages";
import { useAppNavigation } from "../../hooks/useAppNavigation";
import { useContext, useRef } from "react";
import { Store } from "../../store";
import LoadingModal from "../LoadingModal";
import { paymentMethods } from "../../pages/LocalMoneyTransfer";
import { removeMoneyTransferData } from "../../actions";

const Container = styled("div")({
  display: "grid",
  justifyContent: "center",
  alignContent: "center",
});

const APP_ID: string = process.env.REACT_APP_SQUARE_APP_ID as string; //awsConfig.square_app_id;
const LOCATION_ID: string = process.env.REACT_APP_SQUARE_LOCATION_ID as string; //awsConfig.square_location_id;

export default function SquarePaymentGateWay(props: any) {
  const loadingRef = useRef<any>(null);
  const { squarePayment } = useAPI();
  const theme = useTheme();
  const deviceInfo = getDeviceInfoAndIp();
  const { data, reference }: { data: TransferModel; reference: string } = props;
  const { setPage } = useAppNavigation();
  const {
    // state: { user },
    dispatch,
  } = useContext(Store);

  function createVerificationDetails() {
    openLoader(loadingRef);
    const verificationDetails: Square.ChargeVerifyBuyerDetails = {
      amount: data.totalAmount.toString(),
      billingContact: {
        addressLines: ["123 Main Street", "Apartment 1"],
        familyName: data.senderLName,
        givenName: data.senderFName,
        email: data.senderEmail,
        phone: data.senderPhone,
        countryCode: "GB",
        state: "LND",
        city: "London",
      },
      currencyCode: "GBP",
      intent: "CHARGE",
    };
    return verificationDetails;
  }
  function createPaymentRequest() {
    return {
      countryCode: "US",
      currencyCode: "USD",
      lineItems: [
        {
          amount: data.totalAmount.toString(),
          label: "Item to be purchased",
          // id: "SKU-12345",
          // imageUrl: "https://url-cdn.com/123ABC",
          // pending: true,
          // productUrl: "https://my-company.com/product-123ABC"
        },
      ],
      requestBillingContact: false,
      requestShippingContact: false,
      // pending is only required if it's true.
      total: {
        amount: data.totalAmount.toString(),
        label: "Total",
      },
    };
  }
  const sendCreditCardPayment = async (token: any, verifiedBuyer: any) => {
    const [momoFirstName, momoLastName] = data.receiverAccountName.split(" ");

    try {
      const response = await squarePayment({
        locationId: LOCATION_ID,
        success: true,
        RequestBillingContact: false,
        accelerated: false,
        category: "DIGITAL_GOODS",
        currencyCode: "USD",
        name: "moneyTransfer",
        sourceId: token.token,
        verificationToken: verifiedBuyer.token,
        facilitatorAccessToken: verifiedBuyer.token,
        payerID: verifiedBuyer.token,
        paymentID: verifiedBuyer.token,
        kudiexOrderId: reference,
        orderId: reference,
        reference: reference,
        mobileMoneyNumber: data.receiverAccountNumber,
        paymentSource: data.paymentMethod,
        momoFirstName: data.receiverFName || momoFirstName,
        momoLastName: data.receiverLName || momoLastName,
        phoneNumber: data.senderPhone,
        value: data.totalAmount,
        email: data.senderEmail,
        paymentInfo: {
          ...data,
          receiverFName:
            data.receiverFName === "" ? momoFirstName : data.receiverFName,
          receiverLName:
            data.receiverLName === "" ? momoLastName : data.receiverLName,
          receiverPhone:
            data.receiverPhone === ""
              ? data.receiverAccountNumber
              : data.receiverPhone,
        },
        authEmail: data.senderEmail,
        authUsername: data?.authUsername,
        ...deviceInfo,
      });
      const { statusCode, success } = response; // const { payment, statusCode, success }
      if (success && statusCode === 200) {
        dispatch(removeMoneyTransferData()); // clear local memory log
        setPage(SCREEN_NAME.paymentSuccess);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(removeMoneyTransferData());
      closeLoader(loadingRef);
    }
  };
  const sendCashAppPayment = async (token: any) => {
    openLoader(loadingRef);
    const [momoFirstName, momoLastName] = data.receiverAccountName.split(" ");

    try {
      const response = await squarePayment({
        locationId: LOCATION_ID,
        success: true,
        RequestBillingContact: false,
        accelerated: false,
        category: "DIGITAL_GOODS",
        currencyCode: "USD",
        name: "moneyTransfer",
        sourceId: token,
        verificationToken: token,
        facilitatorAccessToken: token,
        payerID: token,
        paymentID: token,
        kudiexOrderId: reference,
        orderId: reference,
        reference: reference,
        mobileMoneyNumber: data.receiverAccountNumber,
        paymentSource: data.paymentMethod,
        momoFirstName: data.receiverFName || momoFirstName,
        momoLastName: data.receiverLName || momoLastName,
        phoneNumber: data.senderPhone,
        value: data.totalAmount,
        email: data.senderEmail,
        paymentInfo: {
          ...data,
          receiverFName:
            data.receiverFName === "" ? momoFirstName : data.receiverFName,
          receiverLName:
            data.receiverLName === "" ? momoLastName : data.receiverLName,
          receiverPhone:
            data.receiverPhone === ""
              ? data.receiverAccountNumber
              : data.receiverPhone,
        },
        authEmail: data.senderEmail,
        authUsername: data?.authUsername,
        ...deviceInfo,
      });
      const { statusCode, success } = response; // const { payment, statusCode, success }
      if (success && statusCode === 200) {
        dispatch(removeMoneyTransferData()); // clear local memory log
        setPage(SCREEN_NAME.paymentSuccess);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(removeMoneyTransferData());
      closeLoader(loadingRef);
    }
  };
  return (
    <Container>
      <PaymentForm
        createPaymentRequest={createPaymentRequest}
        applicationId={APP_ID}
        locationId={LOCATION_ID}
        createVerificationDetails={createVerificationDetails}
        cardTokenizeResponseReceived={(token, verifiedBuyer) =>
          sendCreditCardPayment(token, verifiedBuyer)
        }
      >
        <>
          {data.paymentMethod === paymentMethods.creditCard && (
            <CreditCard
              buttonProps={{
                css: {
                  backgroundColor: theme.palette.primary.main,
                  fontSize: "14px",
                  color: "#fff",
                },
              }}
            />
          )}
          {data.paymentMethod === paymentMethods.cashapp && (
            <CashAppPay
              referenceId={reference}
              shape="semiround"
              width="full"
              callbacks={{
                onTokenization: (event) => {
                  openLoader(loadingRef);
                  const { detail } = event;
                  const { tokenResult } = detail;
                  const { token, status } = tokenResult || {};
                  if (status === "OK") {
                    closeLoader(loadingRef);
                    sendCashAppPayment(token);
                  }
                },
              }}
            />
          )}
        </>
      </PaymentForm>
      <LoadingModal ref={loadingRef} />
    </Container>
  );
}
