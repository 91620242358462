import { Avatar, Box, InputAdornment, Stack } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import AppTextField from "../../components/AppTextField";
import { useState } from "react";
import { paymentMethods } from "../LocalMoneyTransfer";

const PaymentForm = (props: any) => {
  const {
    errors,
    values,
    touched,
    handleBlur,
    // setFieldValue,
    handleCalculateServiceCharge,
    getorderID,
  } = props;
  const [paymentMethod, setPaymentMethod] = useState<any>(values.paymentMethod);
  const handleChangePaymentMethod = (e: any) => {
    // console.log(e.currentTarget.value);
    getorderID(paymentMethods.creditCard);
    // setFieldValue("paymentMethod", paymentMethods.creditCard);
    // console.log(e.currentTarget.value);
    setPaymentMethod(e.currentTarget.value);
    if (e.currentTarget.value === paymentMethods.creditCard) {
      handleCalculateServiceCharge();
    } else {
      handleCalculateServiceCharge(0.0);
    }
  };
  return (
    <>
      <Stack gap={2} width={"inherit"}>
        <Box>
          <AppTextField
            select
            fullWidth
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDown,
            }}
            onChange={handleChangePaymentMethod}
            value={paymentMethod}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={paymentMethod}
                    src={`images/${paymentMethod}.svg`}
                    sx={{ width: 30, height: 30 }}
                  />
                </InputAdornment>
              ),
            }}
            id="paymentMethod"
            name="paymentMethod"
            onBlur={handleBlur}
            error={Boolean(touched.paymentMethod && errors.paymentMethod)}
            showErrorMessage={Boolean(
              touched.paymentMethod && errors.paymentMethod
            )}
            errorMessage={errors.paymentMethod}
          >
            <option value={paymentMethods.creditCard}>Credit Card</option>
            <option value={paymentMethods.debitCard}>Debit Card</option>
          </AppTextField>
        </Box>
      </Stack>
    </>
  );
};

export default PaymentForm;
