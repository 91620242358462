import {
  Avatar,
  Box,
  Card,
  CardContent,
  Link,
  List,
  Stack,
  Typography,
} from "@mui/material";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAppNavigation } from "../hooks/useAppNavigation";
import AppListItem from "../components/AppListItem";
import { SCREEN_NAME } from ".";
import useAPI from "../hooks/useApi";
import { useContext, useRef } from "react";
import LoadingModal from "../components/LoadingModal";
import { saveMoneyTransferData } from "../actions";
import { Store } from "../store";
import { ReceiveMethod } from "../typing";
import { countries } from "../utils/countries";
import { ReceiverMethods } from "./transferForm/Receiver";
import { paymentMethods } from "./LocalMoneyTransfer";

const DashboardPage = () => {
  const { setPage } = useAppNavigation();
  const handleGoToTransactionHistoryPage = () => setPage(SCREEN_NAME.history);
  const loadingRef = useRef<any>(null);
  const { history } = useAPI();
  const {
    state: { user },
    dispatch,
  } = useContext(Store);

  const handleResend = (historyData: any) => {
    const data = {
      dollarAmount: +historyData?.dollarAmount || 0,
      cedisAmount: historyData?.cedisAmount || 0,
      senderFName: historyData?.senderFName || user?.attributes?.given_name,
      senderLName: historyData?.senderLName || user?.attributes?.family_name,
      senderEmail: historyData?.senderEmail || user?.attributes?.email,
      senderPhone: historyData?.senderPhone || user?.attributes?.phone_number,
      senderCountry: historyData?.senderCountry || "Ghana",
      receiverFName: historyData?.receiverFName || "",
      receiverLName: historyData?.receiverLName || "",
      receiverEmail: historyData?.receiverEmail || "",
      receiverPhone: historyData?.receiverAccountNumber || "",
      receiverCountry: historyData?.receiverCountry || countries[0].name,
      receiveMethod: historyData?.receiveMethod || ReceiveMethod.mobileMoney,
      receiverAccountName: historyData?.receiverAccountName || "",
      receiverAccountNumber: historyData?.receiverAccountNumber || "",
      receiveChannel: historyData?.receiveChannel || ReceiverMethods[0].value,
      paymentMethod: historyData?.paymentMethod || paymentMethods.creditCard,
      serviceCharge: 0,
      totalAmount: 0,
      rate: historyData?.rate || 0,
      authUsername: historyData?.authUsername || user?.username,
      authEmail: historyData?.authEmail || user?.attributes?.email,
      modeOfPayment: historyData.modeOfPayment,
    };
    // console.log(data);
    dispatch(saveMoneyTransferData(data));
    setPage(
      historyData.modeOfPayment === "mobileMoneyTransfer"
        ? SCREEN_NAME.intMoneyTransfer
        : SCREEN_NAME.localMoneyTransfer
    );
  };
  return (
    <>
      <Stack sx={{ gap: 3, width: "100%" }}>
        <Stack sx={{ flexDirection: "row", gap: 2 }}>
          <Card
            sx={{
              width: "50%",
              borderRadius: 5,
              height: 100,
              backgroundColor: "primary.main",
              cursor: "pointer",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setPage(SCREEN_NAME.localMoneyTransfer)}
            >
              <PointOfSaleIcon sx={{ fontSize: 50, color: "white" }} />
              <Typography
                color={"white"}
                sx={{ fontSize: 11, textAlign: "center" }}
              >
                Pay Direct
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: "50%",
              borderRadius: 5,
              height: 100,
              backgroundColor: "primary.main",
              cursor: "pointer",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setPage(SCREEN_NAME.intMoneyTransfer)}
            >
              <AccountBalanceIcon sx={{ fontSize: 50, color: "white" }} />
              <Typography
                sx={{ fontSize: 11, textAlign: "center", color: "white" }}
              >
                International Transfer
              </Typography>
            </CardContent>
          </Card>
        </Stack>
        {history?.length > 0 && (
          <Stack sx={{ width: "100%" }}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: 15,
                color: "grey",
              }}
            >
              Send again to
            </Typography>

            <Stack
              sx={{
                width: "inherit",
                flexDirection: "row",
                overflowX: "scroll",
                gap: 2,
                py: 1,
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "&-ms-overflow-style:": {
                  display: "none",
                },
              }}
            >
              {history?.slice(0, 4).map((item: any, index: number) => (
                <Card
                  key={index}
                  sx={{
                    minWidth: 180,
                    borderRadius: 5,
                    height: 100,
                    backgroundColor: "primary.main",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => handleResend(item)}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Stack>
                        <Typography
                          color={"white"}
                          sx={{
                            fontSize: 12,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 100,
                            fontWeight: "bold",
                          }}
                        >
                          {item.receiverAccountName}
                        </Typography>
                        <Typography
                          color={"white"}
                          sx={{ fontSize: 10, textAlign: "left" }}
                        >
                          ${item.dollarAmount}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Avatar
                          alt={item.paymentMethod}
                          src={`images/${item.paymentMethod}.svg`}
                          sx={{ width: 30, height: 30 }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        height: 40,
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        px: 2,
                      }}
                    >
                      <Link color={"primary"} sx={{ fontSize: 15 }}>
                        Send again
                        <ArrowForwardIosIcon sx={{ fontSize: 8, ml: 1 }} />
                      </Link>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Stack>
        )}
        {history?.length > 0 && (
          <Stack sx={{ width: "100%" }}>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "grey",
                  fontSize: 15,
                }}
              >
                Transaction History
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleGoToTransactionHistoryPage}
              >
                <Typography
                  color="inherit"
                  sx={{ fontWeight: "bold", fontSize: 12 }}
                >
                  See more
                </Typography>
                <NavigateNextIcon />
              </Box>
            </Stack>
            <List
              sx={{
                width: "100%",
                // maxWidth: 350,
                bgcolor: "background.paper",
                mt: 2,
              }}
            >
              <Stack sx={{ gap: 3 }}>
                {history?.slice(0, 6).map((item: any, index: number) => (
                  <AppListItem key={index} history={item} />
                ))}
              </Stack>
            </List>
          </Stack>
        )}
        {history?.length === 0 && (
          <Typography>You have not made any transaction yet</Typography>
        )}
        <LoadingModal ref={loadingRef} />
      </Stack>
    </>
  );
};

export default DashboardPage;
