import {
  Box,
  List,
  Paper,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { Store } from "../store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LogoutIcon from "@mui/icons-material/Logout";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Person2Icon from "@mui/icons-material/Person2";
import { useAuthentication } from "../hooks/useAuthentication";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { activateDarkMode, deActivateDarkMode } from "../actions";
import { SCREEN_NAME } from ".";
import useAPI from "../hooks/useApi";
import BadgeAvatars from "../components/BadgeAvatars";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const ListItem = (props: any) => {
  const { icon, text, onClick } = props;
  return (
    <>
      <Paper
        sx={{ elevation: 3, p: 2, borderRadius: 3, cursor: "pointer" }}
        onClick={onClick}
      >
        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Stack sx={{ flexDirection: "row", gap: 2 }}>
            {icon}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>{text}</Typography>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <NavigateNextIcon />
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default function SettingsPage() {
  const [cleared, setCleared] = useState(false);
  const [verificationURL, setVerificationURL] = useState("#");
  const { getClearance, getVerificationURL } = useAPI();
  const {
    state: { user, darkMode },
    dispatch,
  } = useContext(Store);

  const switchThemeHandler = function () {
    if (!darkMode) {
      dispatch(activateDarkMode(true));
    } else {
      dispatch(deActivateDarkMode(false));
    }
  };

  const { setPage } = useAppNavigation();

  const {
    authProvider: { signOut },
  } = useAuthentication();

  const handleLogOut = async () => await signOut();
  const handleGoToProfilePage = () => setPage(SCREEN_NAME.profile);
  // const handleCheckMFAStatus = async (
  //   mfaType: "TOTP" | "NOMFA" | "SMS_MFA" = "NOMFA"
  // ) => {
  //   const mfaStatus = await checkMFAStatus();
  //   if (mfaStatus === "NOMFA") {
  //     const response = await setMFA(mfaType);
  //     console.log(response);
  //   } else {
  //     const response = await setMFA(mfaType);
  //     console.log(response);
  //   }
  // };
  const isCleared = useCallback(async () => {
    try {
      const response = await getClearance(user.username);
      if (Object.keys(response).length > 0) setCleared(true);
      //set cleared to true
      // if cleared is true, continue, else get send user to
    } catch (error) {
      console.log(error);
    }
  }, [getClearance, user]);

  const getVerificationLink = useCallback(async () => {
    try {
      const response = await getVerificationURL({
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        email: user.attributes.email,
        phoneNumber: user.attributes.phone_number,
      });
      if (response?.redirectUrl) setVerificationURL(response?.redirectUrl);
      // open modal and with button to send user to url to complete verification
    } catch (error) {
      console.log(error);
    }
  }, [user, getVerificationURL]);

  const handleGoVerify = () => window.open(verificationURL, "_blank");

  useEffect(() => {
    isCleared();
    getVerificationLink();
  }, [isCleared, getVerificationLink]);
  return (
    <>
      <Stack pb={7} gap={3} sx={{ width: "100%" }}>
        <Stack
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          {/* <Avatar
            src="images/avatar.jpeg"
            sx={{ width: 100, height: 100 }}
            alt="Profile Picture"
          /> */}
          <BadgeAvatars
            avatarLink="images/avatar.jpeg"
            badgeIcon={
              cleared ? (
                <VerifiedIcon
                  color="success"
                  sx={{ backgroundColor: "white", borderRadius: "50%" }}
                />
              ) : (
                <NewReleasesIcon
                  color="error"
                  sx={{ backgroundColor: "white", borderRadius: "50%" }}
                />
              )
            }
            width={100}
            height={100}
          />
          <Stack>
            <Typography fontWeight={"Bold"} fontSize={20} textAlign={"center"}>
              {`${user?.username}`}
            </Typography>
            <Typography color={"gray"} fontSize={12} textAlign={"center"}>
              {user?.attributes?.email}
            </Typography>
            <Typography color={"gray"} fontSize={12} textAlign={"center"}>
              {user?.attributes?.phone_number}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <List
            sx={{
              width: "100%",
              // maxWidth: 350,
              bgcolor: "background.paper",
              mt: 2,
            }}
          >
            <Stack sx={{ gap: 2 }}>
              <ListItem
                onClick={handleGoToProfilePage}
                text="Profile"
                icon={<Person2Icon />}
              />
              <Paper sx={{ elevation: 3, p: 2, borderRadius: 3 }}>
                <Stack
                  sx={{ flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Stack sx={{ flexDirection: "row", gap: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DarkModeIcon />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Change Theme</Typography>
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MaterialUISwitch
                      checked={!darkMode}
                      onClick={switchThemeHandler}
                    />
                  </Box>
                </Stack>
              </Paper>

              {!cleared && (
                <ListItem
                  onClick={() => handleGoVerify()}
                  text="Complete verification"
                  icon={<VerifiedUserIcon />}
                />
              )}
              <ListItem
                onClick={handleLogOut}
                text="Logout"
                icon={<LogoutIcon />}
              />
              {/* <ListItem
                onClick={() => handleCheckMFAStatus("SMS_MFA")}
                text={
                  user?.preferredMFA === "NOMFA"
                    ? "Activate SMS MFA"
                    : "Deactivate SMS MFA"
                }
                icon={<LogoutIcon />}
              />
              <ListItem
                onClick={() => handleCheckMFAStatus("TOTP")}
                text={
                  user?.preferredMFA === "NOMFA"
                    ? "Activate AUTH APP MFA"
                    : "Deactivate AUTH APP MFA"
                }
                icon={<LogoutIcon />}
              /> */}
            </Stack>
          </List>
        </Stack>
      </Stack>
    </>
  );
}
