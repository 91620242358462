import {
  Stack,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

const LoadingModal = (props: any, ref: any) => {
  const [showModal, setShowModal] = useState(false);

  const open = useCallback(() => {
    setShowModal(true);
  }, []);
  const close = useCallback(() => {
    setShowModal(false);
  }, []);
  useImperativeHandle(ref, () => ({
    open: () => open(),
    close: () => close(),
  }));
  return (
    <Dialog open={showModal} onClose={close}>
      <DialogContent>
        <Stack
          sx={{
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} />
          <Typography fontSize="md">Loading</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(LoadingModal);
