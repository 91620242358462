import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Grow,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useContext, useState } from "react";
import { Store } from "../store";
import HomePage from "../pages/Home";
import { appLocalStorage } from "../utils/AppLocalStorage";

type SideNavProps = {
  handleSetScreen: any;
};

const SideNav = ({ handleSetScreen }: SideNavProps) => {
  const { state, dispatch } = useContext(Store);
  const { currentPage } = state;
  const [value, setValue] = useState(currentPage || 0);
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setValue(newValue);
    appLocalStorage.setItem("page", newValue);
    dispatch({ type: "CHANGE_PAGE", payload: newValue });
    if (newValue === "home") {
      handleSetScreen(<HomePage />);
    }
  };
  return (
    <Box
      sx={{
        position: "fixed",
        right: 30,
        top: "40%",
        display: "flex",
        flexDirection: "column",
        // width: "0%",
      }}
    >
      <BottomNavigation
        value={value}
        onChange={handleChange}
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: 5,
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& .Mui-selected": {
            "& .MuiBottomNavigationAction-label": {
              fontSize: (theme) => theme.typography.caption,
              transition: "ease-in",
              fontWeight: "bold",
              lineHeight: "20px",
            },
            "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
              color: (theme) => theme.palette.primary.main,
            },
          },
        }}
      >
        <BottomNavigationAction
          value="dashboard"
          icon={
            <Grow in={true} timeout={500} defaultValue={"dashboard"}>
              <HomeIcon fontSize="large" />
            </Grow>
          }
        />
        <BottomNavigationAction
          value="home"
          icon={
            <Grow in={true} timeout={500} defaultValue={"home"}>
              <CurrencyExchangeIcon fontSize="large" />
            </Grow>
          }
        />
        <BottomNavigationAction
          value="history"
          icon={
            <Grow in={true} timeout={500} defaultValue={"history"}>
              <HistoryIcon fontSize="large" />
            </Grow>
          }
        />
        <BottomNavigationAction
          value="settings"
          icon={
            <Grow in={true} timeout={2000} defaultValue={"settings"}>
              <SettingsIcon fontSize="large" />
            </Grow>
          }
        />
      </BottomNavigation>
    </Box>
  );
};

export default SideNav;
