import * as React from "react";
import {
  Box,
  TextField,
  Typography,
  Paper,
  Stack,
  IconButton,
  Avatar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { chats } from "../data/fakeMessageData";
import { Cancel } from "@mui/icons-material";

const Chat = (props: any) => {
  const [input, setInput] = React.useState("");
  const messages = chats[0];

  const handleSend = () => {
    if (input.trim() !== "") {
      setInput("");
    }
  };

  const handleInputChange = (event: any) => {
    setInput(event.target.value);
  };
  return (
    <>
      <Paper elevation={3}>
        <Stack
          justifyContent={"space-between"}
          gap={0.5}
          p={2}
          flexDirection={"row"}
        >
          <Stack flexDirection={"row"} gap={1}>
            <Avatar
              alt="Avatar"
              src={messages.picture}
              sx={{ width: 30, height: 30 }}
            />
            <Stack flexDirection={"column"} gap={0}>
              <Typography fontWeight={"bold"} fontSize={10}>
                {messages.name}
              </Typography>
              <Stack
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"flex-end"}
                gap={0.5}
              >
                <Typography color={"gray"} fontSize={8}>
                  online
                </Typography>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: "green",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <IconButton
            onClick={props.handleClose}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "primary.main",
              ":hover": {
                backgroundColor: "primary.main",
              },
            }}
          >
            <Cancel sx={{ color: "white" }} />
          </IconButton>
        </Stack>
      </Paper>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          pb: 10,
        }}
        mt={7}
      >
        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
          {messages?.chatlog.map((message: any) => (
            <Message key={message.message_id} message={message} />
          ))}
        </Box>
        <Box
          sx={{
            p: 1,
            backgroundColor: "background.default",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Stack
            flexDirection={"row"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <TextField
              id="chatContent"
              sx={{ flexGrow: 2 }}
              fullWidth
              placeholder="Type a message"
              value={input}
              onChange={handleInputChange}
              InputProps={{
                style: { height: 50, borderRadius: 25 },
              }}
            />
            <IconButton
              id="sendChat"
              onClick={handleSend}
              sx={{
                flexGrow: 1,
                width: 50,
                height: 50,
                backgroundColor: "primary.main",
                ":hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              <SendIcon sx={{ color: "white" }} />
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

const Message = ({ message }: { message: any }) => {
  const isReceiver = message.side === "left";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isReceiver ? "flex-start" : "flex-end",
        mb: 1,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          maxWidth: 250,
          p: 1,
          backgroundColor: isReceiver ? "#1776d1" : "#2c3742",
          color: "white",
          borderRadius: isReceiver
            ? "20px 20px 20px 5px"
            : "20px 20px 5px 20px",
        }}
      >
        <Typography variant="body1" fontSize={12}>
          {message.text}
        </Typography>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography component="span" fontSize={8} fontWeight={"bold"}>
            {message.timestamp}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Chat;
