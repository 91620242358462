import { Auth } from "aws-amplify";
import awsConfig from "../awsConfig";
import useHttp from "@fast-ninjas/hooks.use-http";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Store } from "../store";
import { closeLoader, openLoader } from "../utils";
import { useAuthentication } from "./useAuthentication";

const API_KEY = process.env.REACT_APP_XMO_API_KEY;

type ApiProps = {
  apiEndpoint?: string;
};

export default function useAPI(props?: ApiProps) {
  const BASE_URL = props?.apiEndpoint || awsConfig.xmoApiEndpoint;
  const { post, get } = useHttp();
  const [limit, setLimit] = useState<any | null>(null);
  const [history, setHistory] = useState<any | null>(null);
  const {
    state: { user },
  } = useContext(Store);
  const loadingRef = useRef();
  const {
    authProvider: { signOut },
  } = useAuthentication();
  const createAccessHeader = useCallback(async (apiKey?: string) => {
    try {
      const {
        signInUserSession: { idToken },
      } = await Auth.currentAuthenticatedUser();
      // console.log("TOKEN: ", accessToken?.jwtToken);
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken.jwtToken}`,
      };
    } catch (error) {
      signOut();
    }
    // eslint-disable-next-line
  }, []);
  const createAPIKEYAccessHeader = useCallback(async (apiKey?: string) => {
    return {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    };
  }, []);
  const createNonAPIKEYAccessHeader = useCallback(async () => {
    return {
      "Content-Type": "application/json",
    };
  }, []);

  const squarePayment = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/square/processSquareCreditCardPayment`,
          { event: data },
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );

  const payPalPayment = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/paypal/recievePayPalPayment`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
        return null;
      }
    },
    [post, createAccessHeader, BASE_URL]
  );

  const getHistory = useCallback(async () => {
    openLoader(loadingRef);
    const authHeader = await createAccessHeader();
    const data = {
      senderPhoneNumber: user?.attributes?.phone_number,
    };
    try {
      const result = await post(
        `${BASE_URL}/history/getHistoryForUsersMoneyTransfer`,
        data,
        authHeader
      );
      const res = await result.json();
      if (res.customerData) {
        // console.log(response);
        const sortedAsc = res.customerData.sort(
          (objA: any, objB: any) =>
            Number(new Date(objB.pendingDate)) -
            Number(new Date(objA.pendingDate))
        );
        closeLoader(loadingRef);
        setHistory(sortedAsc);
      } else {
        setHistory([]);
      }
    } catch (err) {
      closeLoader(loadingRef);
      console.log("ERRORRRRR", err);
      return null;
    }
  }, [post, createAccessHeader, BASE_URL, user]);

  const getRates = useCallback(async () => {
    const authHeader: any = await createNonAPIKEYAccessHeader();
    try {
      const result = await get(`${BASE_URL}/rate/getRate`, authHeader);
      return result;
    } catch (err) {
      console.log("ERRORRRRR", err);
    }
    return [];
  }, [get, createNonAPIKEYAccessHeader, BASE_URL]);

  const convertCurrency = useCallback(async () => {
    const authHeader: any = await createAPIKEYAccessHeader(API_KEY);
    const data = {
      from: "USD",
    };
    try {
      const result = await post(`${BASE_URL}/rate/converter`, data, authHeader);
      return result.json();
    } catch (err) {
      console.log("ERRORRRRR", err);
    }
    return [];
  }, [post, createAPIKEYAccessHeader, BASE_URL]);

  const getInternalRates = useCallback(async () => {
    const authHeader: any = await createAccessHeader();
    try {
      const result = await get(
        `${BASE_URL}/getInTRate/getInternalRate`,
        authHeader
      );
      return result;
    } catch (err) {
      console.log("ERRORRRRR", err);
    }
    return [];
  }, [get, createAccessHeader, BASE_URL]);

  const getOrderId = useCallback(
    async (paymentMethod: string) => {
      const authHeader = await createAccessHeader();
      const data = { requestApp: paymentMethod };
      try {
        const result = await post(
          `${BASE_URL}/rate/getOrderId`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );
  const getLimit = useCallback(async () => {
    const authHeader = await createAccessHeader();
    const data = {};
    try {
      const result = await post(`${BASE_URL}/aml/limit`, data, authHeader);
      setLimit(await result.json());
    } catch (err) {
      console.log("ERRORRRRR", err);
    }
    return [];
  }, [post, createAccessHeader, BASE_URL]);

  const getClearance = useCallback(
    async (username: string) => {
      const authHeader = await createAccessHeader();
      const data = { userName: username };
      try {
        const result = await post(
          `${BASE_URL}/verification/getclear`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );

  const getVerificationURL = useCallback(
    async (data: any) => {
      // data: {firstName, lastName, email, phoneNumber}
      const authHeader = await createAccessHeader();
      try {
        const result = await post(
          `${BASE_URL}/verification/getVerificationURL`,
          data,
          authHeader
        );
        return result.json();
      } catch (err) {
        console.log("ERRORRRRR", err);
      }
      return [];
    },
    [post, createAccessHeader, BASE_URL]
  );

  useEffect(() => {
    // if (user?.signInUserSession) {
    getLimit();
    getHistory();
    // }
  }, [getLimit, getHistory]);

  return {
    squarePayment,
    payPalPayment,
    getHistory,
    getRates,
    getInternalRates,
    getOrderId,
    convertCurrency,
    limit,
    history,
    getClearance,
    getVerificationURL,
  };
}
