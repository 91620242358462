import { Auth } from "aws-amplify";
import { useCallback, useContext } from "react";
import {
  loginFailure,
  loginSuccess,
  logoutUser,
} from "../actions/authentication";
import {
  Credentials,
  CognitoUser,
  SignUpFormData,
  VerifyAccountData,
} from "../typing";
import { Store } from "../store";
import { SCREEN_NAME } from "../pages";
import { useAppNavigation } from "./useAppNavigation";
import { appLocalStorage } from "../utils/AppLocalStorage";

const TOKEN_EXPIRATION_TIME = 1; //in hours

export const SecureDataKeys = {
  accessToken: "XMOAccessToken",
  credentials: "XMOCredentials",
};

export function useAuthentication() {
  const { setPage } = useAppNavigation();
  const {
    state: {
      user,
      auth: { isLoading },
    },
    dispatch,
  } = useContext(Store);

  async function getCurrentAuthenticatedUser() {
    const response = await Auth.currentAuthenticatedUser();
    return response;
  }

  // async function getCurrentSession() {
  //   const response = await Auth.currentSession();
  //   return Promise.resolve(response);
  // }

  /**
   * SignUp
   */

  function saveUserCredentials(username: string, password: string) {
    appLocalStorage.setItem(
      SecureDataKeys.credentials,
      JSON.stringify([username, password])
    );
  }

  async function signUp({
    firstName,
    lastName,
    username,
    email,
    password,
    phoneNumber,
  }: SignUpFormData): Promise<{ success: boolean; isConfirmed: boolean }> {
    const response = await Auth.signUp({
      password,
      username,
      autoSignIn: { enabled: true },
      attributes: {
        email,
        phone_number: phoneNumber,
        given_name: firstName,
        family_name: lastName,
      },
    });

    const success = response.user !== null;
    if (success) {
      saveUserCredentials(username, password);
    }
    return {
      success,
      isConfirmed: response.userConfirmed,
    };
  }

  async function confirmSignUp({
    username,
    otpCode,
  }: VerifyAccountData): Promise<any> {
    await Auth.confirmSignUp(username, otpCode);
  }

  async function resendSignUp(username: string) {
    await Auth.resendSignUp(username);
  }

  const confirmSignIn = useCallback(
    async (user: any, verificationCode: string) => {
      try {
        const response = await Auth.confirmSignIn(user, verificationCode);
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  /**
   * SignIn
   */

  async function saveUserInSession(user: CognitoUser) {
    const tokenExpirationDate = new Date();
    tokenExpirationDate.setHours(
      tokenExpirationDate.getHours() + TOKEN_EXPIRATION_TIME
    );
    user["tokenExpirationDate"] = tokenExpirationDate;
    const tokenData = user;

    dispatch(loginSuccess(tokenData));
  }

  async function signIn({
    username,
    password,
  }: Credentials): Promise<CognitoUser> {
    const CognitoUser = await Auth.signIn(username, password);
    if (CognitoUser) {
      saveUserInSession(CognitoUser);
      return Promise.resolve(CognitoUser);
    }

    dispatch(loginFailure());
    throw Error("Login failed");
  }

  /**
   * SignOut
   */

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const deleteLoggedInSession = useCallback(async () => {
    // appLocalStorage.removeItem(SecureDataKeys.accessToken);
    // appLocalStorage.removeItem(SecureDataKeys.credentials);
    appLocalStorage.clearStorage();
    clearCacheData();
  }, []);

  const signOut = useCallback(async () => {
    try {
      setPage(SCREEN_NAME.home);
      dispatch(logoutUser());
      await Auth.signOut({ global: true });

      await deleteLoggedInSession();
    } catch (ex) {
      console.log(ex);
    }
  }, [dispatch, setPage, deleteLoggedInSession]);

  /**
   * Forgot Password
   */
  async function forgotPassword(username: string) {
    await Auth.forgotPassword(username);
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    const authUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(authUser, oldPassword, newPassword);
  }

  async function updateProfile(attributes: any) {
    const authUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(authUser, attributes);
    return await Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  async function updateProfileInSession() {
    const updatedUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    dispatch(
      loginSuccess({
        tokenExpirationDate: user.tokenExpirationDate,
        ...updatedUser,
      })
    );
  }

  const confirmAttributeUpdate = async (attribute: string, code: string) => {
    try {
      const response = await Auth.verifyCurrentUserAttributeSubmit(
        attribute,
        code
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  async function forgotPasswordSetNewPassword(
    username: string,
    code: string,
    newPassword: string
  ) {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  /**
   * MFA
   */

  const checkMFAStatus = useCallback(async () => {
    try {
      const response = await Auth.getPreferredMFA(
        await getCurrentAuthenticatedUser()
      );
      return response;
    } catch (ex) {
      console.log(ex);
    }
  }, []);

  const setMFA = useCallback(
    async (
      mfaMethod: "SMS" | "NOMFA" | "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | "TOTP"
    ) => {
      try {
        const response = await Auth.setPreferredMFA(
          await getCurrentAuthenticatedUser(),
          mfaMethod
        );
        return response;
      } catch (ex) {
        console.log(ex);
      }
    },
    []
  );

  return {
    isLoading,
    user: user as CognitoUser,
    authProvider: {
      signIn,
      signOut,
      signUp,
      confirmSignUp,
      confirmSignIn,
      resendSignUp,
      forgotPassword,
      forgotPasswordSetNewPassword,
      changePassword,
      getCurrentAuthenticatedUser,
      updateProfile,
      updateProfileInSession,
      confirmAttributeUpdate,
      checkMFAStatus,
      setMFA,
    },
  };
}
