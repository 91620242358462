import PaypalPaymentGateWay from "../components/payment/PaypalPaymentGateWay";
// import SquarePaymentGateWay from "../components/payment/SquarePaymentGateWay";

const PaymentPage = () => {
  return (
    <>
      {/* <SquarePaymentGateWay /> */}
      <PaypalPaymentGateWay />
    </>
  );
};

export default PaymentPage;
