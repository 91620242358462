import { Button, Container, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function ErrorPage() {
  return (
    <>
      <Container>
        <Stack
          flexDirection={"column"}
          width={"100%"}
          height={"80vh"}
          // justifyContent={"center"}
          alignItems={"center"}
          gap={5}
        >
          <Typography
            color={"primary"}
            sx={{ fontSize: 100, fontWeight: "bold" }}
          >
            Oops!
          </Typography>
          <Typography component="p" sx={{ fontSize: 20, textAlign: "center" }}>
            The page you are looking for has not been found
          </Typography>
          <NavLink to="/">
            <Button>Back to home page</Button>
          </NavLink>
        </Stack>
      </Container>
    </>
  );
}
