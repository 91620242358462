export const chats = [
  {
    id: 2,
    name: "Alex",
    picture: "https://image.ibb.co/cA2oOb/alex_1.jpg",
    latest_timestamp: "10:00 AM",
    lastChat:
      "Or maybe not, let me check logistics and call you. Give me sometime",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 3,
    name: "Bob",
    picture: "https://image.ibb.co/gSyTOb/bob_1.jpg",
    latest_timestamp: "12:30 AM",
    lastChat: "Alright",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 4,
    name: "Luke",
    picture: "https://image.ibb.co/jOzeUG/luke_1.jpg",
    latest_timestamp: "4:12 PM",
    lastChat: "I will look into it",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 5,
    name: "Bane",
    picture: "https://image.ibb.co/cBZPww/bane_1.jpg",
    latest_timestamp: "7:53 PM",
    lastChat: "Exactly my point!",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 6,
    name: "Darth Vader",
    picture: "https://image.ibb.co/j4Ov3b/darth_vader_1.png",
    latest_timestamp: "1:09 PM",
    lastChat: "Not quite the same.",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 7,
    name: "Zach",
    picture: "https://image.ibb.co/b4kxGw/zach_1.jpg",
    latest_timestamp: "Yesterday",
    lastChat: "I thought that the event was over long ago",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 8,
    name: "Katie",
    picture: "https://image.ibb.co/eLVWbw/katie_1.jpg",
    latest_timestamp: "Yesterday",
    lastChat: "nothing",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 9,
    name: "Chloe",
    picture: "https://image.ibb.co/ncAa3b/chloe_1.jpg",
    latest_timestamp: "Wednesday",
    lastChat: "sure i'll take it from you",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 10,
    name: "Kennith",
    picture: "https://image.ibb.co/fQKPww/kennith_1.jpg",
    latest_timestamp: "Wednesday",
    lastChat: "Take care, bye",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 11,
    name: "Tara",
    picture: "https://image.ibb.co/dM6hib/tara_1.jpg",
    latest_timestamp: "Monday",
    lastChat: "Not today",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 12,
    name: "Gary",
    picture: "https://image.ibb.co/gsF8Ob/gary_1.jpg",
    latest_timestamp: "Sunday",
    lastChat: "Whatever works for you!",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 13,
    name: "Zoey",
    picture: "https://image.ibb.co/nd0Wbw/zoey_1.jpg",
    latest_timestamp: "8/12/2017",
    lastChat: "Will get in touch",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 14,
    name: "Ash",
    picture: "https://image.ibb.co/iasYpG/ash_1.jpg",
    latest_timestamp: "6/12/2017",
    lastChat: "Ok",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
  {
    id: 15,
    name: "Zen",
    picture: "https://image.ibb.co/eeqWbw/zen_1.jpg",
    latest_timestamp: "19/11/2017",
    lastChat: "Lol",
    chatlog: [
      {
        text: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable",
        timestamp: "07:05 AM",
        side: "left",
        message_id: 4,
      },
      {
        text: "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        timestamp: "07:00 AM",
        side: "right",
        message_id: 3,
      },
      {
        text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested",
        timestamp: "06:58 AM",
        side: "left",
        message_id: 2,
      },
      {
        text: "Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham",
        timestamp: "06:55 AM",
        side: "right",
        message_id: 1,
      },
    ],
  },
];

export const users = [
  {
    firstName: "Mariah",
    lastName: "Kay",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/1.jpeg",
  },
  {
    firstName: "Aduah",
    lastName: "Frimpong",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/2.jpeg",
  },
  {
    firstName: "Abena",
    lastName: "Asamoah",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/3.jpeg",
  },
  {
    firstName: "Afua",
    lastName: "Kofi",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/4.jpeg",
  },
  {
    firstName: "Esi",
    lastName: "Fiagbe",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/5.jpeg",
  },
  {
    firstName: "Akua",
    lastName: "Essuman",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/6.jpeg",
  },
  {
    firstName: "Kofi",
    lastName: "David",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/7.jpeg",
  },
  {
    firstName: "Joey",
    lastName: "Samson",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/8.jpeg",
  },
  {
    firstName: "Nana",
    lastName: "Samson",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/9.jpeg",
  },
  {
    firstName: "Patrick",
    lastName: "Samson",
    age: 25,
    occupation: "Student",
    placeOfWork: "University of Ghana",
    image: "/10.jpeg",
  },
];
