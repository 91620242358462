import { LoadingButton } from "@mui/lab";

const IsLoadingAppButton = (props: any) => {
  return (
    <LoadingButton
      {...props}
      variant="contained"
      sx={{
        ...props.sx,
        borderRadius: "15px",
        paddingY: 2,
        height: "45px",
      }}
    />
  );
};

export default IsLoadingAppButton;
