import React, { useState } from "react";
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { Box, Drawer } from "@mui/material";
import styled from "@emotion/styled";

export const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "gray",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  zIndex: 10000,
  left: "calc(50% - 15px)",
}));

const AppBottomSheetDrawer = (props: any, ref: any) => {
  const bottomSheetModalRef = useRef(null);
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: any) => () => {
    setOpen(newOpen);
  };

  const openSheet = useCallback(() => {
    setOpen(true);
  }, []);
  const closeSheet = useCallback(() => {
    setOpen(false);
  }, []);

  useImperativeHandle(ref, () => ({
    openSheet: () => openSheet(),
    closeSheet: () => closeSheet(),
  }));

  return (
    <>
      <Drawer
        ref={bottomSheetModalRef}
        anchor={"bottom"}
        open={open}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: "white",
            height: "80%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        }}
        sx={{
          position: "relative",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Puller />
        {props.children}
      </Drawer>
    </>
  );
};

export default forwardRef(AppBottomSheetDrawer);
