import { createBrowserRouter } from "react-router-dom";
import Index from "./pages";
import Home from "./pages";
import Chat from "./components/Chat";
import ErrorPage from "./pages/Error";

const router = createBrowserRouter([
  { path: "/", element: <Index /> },
  { path: "/error", element: <ErrorPage /> },
  { path: "/currency/converter", element: <Home /> },
  { path: "/chat", element: <Chat /> },
]);

export default router;
